import { NgModule } from '@angular/core';
import { RouterModule, Route } from '@angular/router';

import { XpoBrowserGuard } from '@xpo-ltl/ngx-ltl-core';
import { XpoAuthenticationGuard } from '@xpo/ngx-auth';
import { RouterUriComponents } from '@shared/enums';
import { RoleCheckGuard } from '@shared/guards/role-check-guard';

import { NotAuthorizedComponent } from '@core/layout/not-authorized/not-authorized.component';

export const RouterDefinitions: Route[] = [
  {
    path: RouterUriComponents.NOT_AUTHORIZED_PAGE,
    component: NotAuthorizedComponent,
  },
  {
    path: RouterUriComponents.INVOICING_MAIN_PAGE,
    canActivate: [XpoAuthenticationGuard, RoleCheckGuard],
    loadChildren: () => import('./features/view-invoice/view-invoice.module').then((m) => m.ViewInvoiceModule),
  },
  {
    path: RouterUriComponents.INVOICING_RESEND_PAGE,
    loadChildren: () => import('./features/resend-invoices/resend-invoices.module').then((m) => m.ResendInvoicesModule),
  },
  {
    path: RouterUriComponents.RELEASE_INVOICE,
    loadChildren: () =>
      import('./features/release-invoices/release-invoices.module').then((m) => m.ReleaseInvoicesModule),
  },
  {
    path: RouterUriComponents.RFC_MANAGEMENT,
    loadChildren: () => import('./features/rfc-management/rfc-management.module').then((m) => m.RfcManagementModule),
  },
  {
    path: '**',
    redirectTo: RouterUriComponents.INVOICING_MAIN_PAGE,
  },
];

@NgModule({
  imports: [RouterModule.forRoot(RouterDefinitions, { enableTracing: false, relativeLinkResolution: 'legacy' })],
  exports: [RouterModule],
  providers: [RoleCheckGuard],
  declarations: [],
})
export class AppRoutingModule {}

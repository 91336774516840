import { Pipe, PipeTransform } from '@angular/core';
import { CurrencyPipe } from '@angular/common';

@Pipe({
  name: 'emptyCurrency',
})
export class EmptyCurrencyPipe extends CurrencyPipe implements PipeTransform {
  transform(value: any, args?: any): any {
    if (value === '-' || !value) {
      return '-';
    }
    if (Number.isNaN(Number.parseFloat(value))) {
      return value;
    }
    const convertedValue = Number.parseFloat(value);
    if (convertedValue === Number.NaN || convertedValue === 0) {
      return '-';
    }
    return super.transform(`${convertedValue}`, args);
  }
}

<div class="xpo-footer">
  <div class="footer-left">
    <span data-test="xpo-footer__copyright">© {{ copyrightYear }} XPO Logistics.</span>
    <span data-test="xpo-footer__version" class="build-version">
      Version: {{ buildVersion }} - {{ browserInfo.name }}: {{ browserInfo.version }}
    </span>
  </div>
  <div class="footer-right">
    <div class="footer-links" data-test="xpo-footer__links">
      <a
        data-test="xpo-footer__links--feedback"
        (click)="feedbackClicked()"
        *ngIf="feedbackService.getFeedbackConfig$() | async">Feedback</a>
    </div>
  </div>
</div>

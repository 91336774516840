<div class="both-bill-dialog">
  <h2 mat-dialog-title>PRO {{ proNumber }} is associated with a Both-Bill Order</h2>
  <div mat-dialog-content>
    <p>Which version of the invoice do you want to view?</p>
  </div>
  <div mat-dialog-actions fxLayoutAlign="end end">
    <button class="invoice-button" (click)="handleSelectItemClicked(ChargeToCd.PPD)" tabindex="-1">Prepaid</button>
    <button class="invoice-button" (click)="handleSelectItemClicked(ChargeToCd.COLL)" tabindex="-1">Collect</button>
  </div>
</div>

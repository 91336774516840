import { Pipe, PipeTransform } from '@angular/core';
import { InvoiceLine } from '@xpo-ltl/sdk-invoice';

@Pipe({
  name: 'totalRate',
})
export class TotalRatePipe implements PipeTransform {
  transform(value: InvoiceLine[], args?: any): any {
    let total = '-';
    if (value && value.length) {
      total = `${value.map((item) => (item.rateAmt ? item.rateAmt : 0)).reduce((a, b) => a + b, 0)}`;
    }
    return total;
  }
}

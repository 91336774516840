import { Pipe, PipeTransform } from '@angular/core';
import { EmptyCurrencyPipe } from '../empty-currency/empty-currency.pipe';

@Pipe({
  name: 'discount',
})
export class DiscountPipe extends EmptyCurrencyPipe implements PipeTransform {
  transform(invoiceLine: any, args?: any): any {
    if (invoiceLine && invoiceLine.lineNbr && invoiceLine.amount) {
      const showNegative =
        invoiceLine.lineNbr && invoiceLine.lineNbr['toLowerCase'] && invoiceLine.lineNbr.toLowerCase() === 'discount';
      return `${showNegative ? '-' : ''}${super.transform(invoiceLine.amount, args)}`;
    }
    return super.transform(invoiceLine ? invoiceLine.amount : 0, args);
  }
}

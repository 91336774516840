import { Pipe, PipeTransform } from '@angular/core';
import { ReferenceNumber } from '@xpo-ltl/sdk-common';

@Pipe({
  name: 'srn',
})
export class SrnPipe implements PipeTransform {
  transform(value: ReferenceNumber, args?: any): any {
    let formattedValue = '';
    if (value) {
      formattedValue = `${value.referenceTypeCd}: ${value.reference}`;
    }
    return formattedValue;
  }
}

import { Pipe, PipeTransform } from '@angular/core';

@Pipe({
  name: 'madCode',
})
export class MadCodePipe implements PipeTransform {
  transform(value: any, args?: any): any {
    return !!value && value !== '0' ? value : '';
  }
}

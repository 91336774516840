import { AcctBasicDetails } from '@xpo-ltl/sdk-customer';

export interface ResendInvoicesDialogDataInterface {
  type: string;
  invoices: any[];
  isMultiple: boolean;
}

export class ResendInvoicesDialogData implements ResendInvoicesDialogDataInterface {
  constructor(
    public type: string,
    public invoices: any[],
    public isMultiple: boolean) {}
}

import { Pipe, PipeTransform } from '@angular/core';
import { InvoiceTransmitStatusCd } from '@xpo-ltl/sdk-common';

@Pipe({
  name: 'transmitStatusCd',
})
export class TransmitStatusCdPipe implements PipeTransform {
  transform(value: InvoiceTransmitStatusCd, args?: any): any {
    let transformedValue = '';
    if (value) {
      switch (value) {
        case InvoiceTransmitStatusCd.DO_NOT_TRANSMIT:
          transformedValue = 'Do Not Transmit';
          break;
        case InvoiceTransmitStatusCd.EXPIRED:
          transformedValue = 'Expired';
          break;
        case InvoiceTransmitStatusCd.NOT_READY:
          transformedValue = 'Not Ready';
          break;
        case InvoiceTransmitStatusCd.PAID:
          transformedValue = 'Paid';
          break;
        case InvoiceTransmitStatusCd.PAID_BELOW_THRESHOLD:
          transformedValue = 'Paid Below Threshold';
          break;
        case InvoiceTransmitStatusCd.PAID_CASH_COLLECTED:
          transformedValue = 'Driver Cash Collected';
          break;
        case InvoiceTransmitStatusCd.PAID_CASH_PPD:
          transformedValue = 'Driver Cash Prepaid';
          break;
        case InvoiceTransmitStatusCd.PAID_SELF_PAID:
          transformedValue = 'Self Invoicing';
          break;
        case InvoiceTransmitStatusCd.TRANSMIT:
          transformedValue = 'Transmit';
          break;
      }
    }
    return transformedValue;
  }
}

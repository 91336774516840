import { Component } from '@angular/core';

import { ConfigManagerService } from '@xpo-ltl/config-manager';

import { ConfigManagerProperties } from '@shared/enums';

@Component({
  selector: 'app-region-banner',
  templateUrl: './region-banner.component.html',
  styleUrls: ['./region-banner.component.scss'],
})
export class RegionBannerComponent {
  constructor(private configManagerService: ConfigManagerService) {}

  get isProd(): boolean {
    return this.configManagerService.getSetting<boolean>(ConfigManagerProperties.production);
  }

  get region(): string {
    return this.configManagerService.getSetting<string>(ConfigManagerProperties.region);
  }
}

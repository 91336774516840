import { Directive } from '@angular/core';
import { AbstractControl, NG_VALIDATORS, Validator, ValidatorFn } from '@angular/forms';

export function phoneFormatValidator(): ValidatorFn {
  return (control: AbstractControl): { [key: string]: any } => {
    const regexTest = /^\(?([0-9]{3})\)?[-. ]?([0-9]{3})[-. ]?([0-9]{4})$/g;
    let returnValue = null;
    if (control.value) {
      const nakedNumber = control.value.replace(/\D/g, '');
      if (!nakedNumber.replace(/0/g, '') || !regexTest.test(control.value)) {
        returnValue = { phoneFormat: { value: control.value } };
      }
    }
    return returnValue;
  };
}

@Directive({
  selector: '[appPhoneValidator]',
  providers: [{ provide: NG_VALIDATORS, useExisting: PhoneFormatValidatorDirective, multi: true }],
})
export class PhoneFormatValidatorDirective implements Validator {
  validate(control: AbstractControl): { [key: string]: any } {
    return phoneFormatValidator()(control);
  }
}

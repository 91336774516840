import { DmsDocTypeCd, InvoiceModeCd, InvoicePresentationFormatCd } from '@xpo-ltl/sdk-common';

export class ResendPreferences {
  invoiceModeCd: InvoiceModeCd;
  attachmentFormat: InvoicePresentationFormatCd;
  emailAddressList: string[];
  emailSubject: string;
  faxNumber: string;
  mailCompanyName: string;
  mailAddress: string;
  mailCity: string;
  mailState: string;
  mailZip: string;
  mailCountry: string;
  attachAs: 'File' | 'Link';
  fileOptions: string[];
  attachmentList: DmsDocTypeCd[];
}

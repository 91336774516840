import { Injectable } from '@angular/core';
import { MatDialog } from '@angular/material/dialog';
import { ActivatedRouteSnapshot, CanActivate, RouterStateSnapshot, UrlSegment, Route, CanLoad } from '@angular/router';

import { User } from '@xpo-ltl/sdk-common';
import { ConfigManagerService } from '@xpo-ltl/config-manager';


import { ConfigManagerProperties } from '@shared/enums';
import { NotAuthorizedComponent } from '@core/layout/not-authorized/not-authorized.component';
import { InvoiceAuditorService } from '@shared/services/invoice-auditor/invoice-auditor.service';
import { XpoLtlLoggedInUserService } from '@xpo-ltl/ngx-ltl';
import { LoggingApiService } from '@xpo-ltl/sdk-logging';
import { AppConstantsService } from '@shared/services/app-constants/app-constants.service';

@Injectable()
export class RoleCheckGuard implements CanActivate, CanLoad {
  constructor(
    private dialog: MatDialog,
    private config: ConfigManagerService,
    private constantsService: AppConstantsService,
    private loginService: XpoLtlLoggedInUserService,
    private invoiceAuditorService: InvoiceAuditorService,
    private loggingService: LoggingApiService
  ) {}

  canActivate(route: ActivatedRouteSnapshot, state: RouterStateSnapshot): Promise<boolean> {
    return this.isAuthorized();
  }

  canLoad(route: Route, segments: UrlSegment[]): Promise<boolean> {
    return this.isAuthorized();
  }

  private isAuthorized(): Promise<boolean> {
    return new Promise((resolve, reject) => {
      const failGuardFunc = () => {
        this.invoiceAuditorService.user = undefined;
        this.loggingService.clearContext(this.constantsService.ROLE_CONTEXT);
        this.loggingService.error(`User Not Authorized`);
        this.showNotAuthorizedDialog();
        reject();
      };

      this.loginService.getLoggedInUser(this.config.getSetting(ConfigManagerProperties.loggedInUserRoot)).subscribe(
        (user: User) => {
          if (this.invoiceAuditorService.isAuthorizedUser(user.roles)) {
            this.invoiceAuditorService.user = user;
            this.invoiceAuditorService.userRoles = user.roles;
            resolve(true);
            return;
          } else {
            failGuardFunc();
          }
        },
        () => {
          failGuardFunc();
        }
      );
    });
  }

  private showNotAuthorizedDialog() {
    this.dialog.open(NotAuthorizedComponent, { disableClose: true });
  }
}

export enum ResendInvoiceDialogFormFields {
  TRANSMISSION_MEDIUM = 'transmissionMedium',
  ATTACHMENT_FORMAT = 'attachmentFormat',
  ATTACHMENT_FILES = 'attachmentFiles',
  ATTACHMENT_AS = 'attachmentAs',
  COMPANY_NAME = 'companyName',
  ADDRESS = 'address',
  CITY = 'city',
  STATE = 'state',
  ZIP_CODE = 'zipCode',
  COUNTRY = 'country',
  EMAIL_ADDRESS = 'emailAddress',
  EMAIL_ADDRESSES = 'emailAddresses',
  EMAIL_SUBJECT = 'emailSubject',
  FAX_NUMBER = 'faxNumber',
  ATTENTION = 'attention',
  FILE_OPTIONS = 'fileOptions',
}

import { Injectable } from '@angular/core';

import { NotificationService } from '@xpo-ltl/data-api';
import { ConfigManagerService } from '@xpo-ltl/config-manager';

import { ConfigManagerProperties } from '@shared/enums';

@Injectable({ providedIn: 'root' })
export class WindowService {
  private window: Window;

  constructor(
    private notificationService: NotificationService,
    private configManagerService: ConfigManagerService) {
    this.window = window;
  }

  openWindow(url: string, target?: string) {
    if (url) {
      this.window.open(url, target || '_blank');
    } else {
      this.notificationService.showSnackBarMessage('Invalid URL', {
        status: 'error',
        durationInMillis: this.configManagerService.getSetting<number>(ConfigManagerProperties.errorToastDuration),
      });
    }
  }

  generateDownloadFile(mimeType: string, data: string, fileName: string) {
    const blob = new Blob([data], { type: mimeType });

    if (window.navigator && window.navigator['msSaveOrOpenBlob']) {
      window.navigator['msSaveOrOpenBlob'](blob, fileName);
    } else {
      const a = document.createElement('a');
      document.body.appendChild(a);
      a.href = URL.createObjectURL(blob);
      a.download = fileName;
      a.click();
      a.remove();
    }
  }
}

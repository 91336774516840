import { AbstractControl } from '@angular/forms';
import { MAT_DIALOG_DATA, MatDialogRef } from '@angular/material/dialog';
import { ChangeDetectionStrategy, Component, Inject, OnInit, ViewChild } from '@angular/core';

import { NotificationService } from '@xpo-ltl/data-api';

import { BehaviorSubject } from 'rxjs';
import { skip, take } from 'rxjs/operators';

import { DocumentViewDialogDataInterface } from '@shared/models';

import { DmsApiService } from '@shared/services/dms-api/dms-api.service';
import { WindowService } from '@shared/services/window-service/window.service';

export class DocumentDetails {
  currentPage = 1;
  totalPages = 1;
  get showNavigation() {
    return this.totalPages > 1;
  }
  document: Uint8Array;
  documentNotFound = false;
  get showWait() {
    return !this.document && !this.documentNotFound;
  }
  errorMessage = '';
}

export class ExternalExporter {
  constructor(
    private windowService: WindowService,
    @Inject(MAT_DIALOG_DATA) public data: DocumentViewDialogDataInterface
  ) {}

  export(pdfBytes) {
    this.windowService.generateDownloadFile(
      'application/pdf',
      pdfBytes,
      `${this.data.documentType.replace(' ', '')}_${this.data.documentNumber}.pdf`
    );
  }
}

@Component({
  selector: 'app-document-view-dialog',
  templateUrl: './document-view-dialog.component.html',
  styleUrls: ['./document-view-dialog.component.scss'],
  changeDetection: ChangeDetectionStrategy.OnPush,
})
export class DocumentViewDialogComponent implements OnInit {
  private docDtls = new DocumentDetails();
  private documentDetailsSubject = new BehaviorSubject<DocumentDetails>(this.docDtls);
  documentDetails$ = this.documentDetailsSubject.asObservable();
  private pdfBytes;
  private externalExporter = new ExternalExporter(this.windowService, this.data);
  @ViewChild('zoomControl') zoomControl: AbstractControl;

  constructor(
    private windowService: WindowService,
    private dmsDocumentService: DmsApiService,
    private notificationService: NotificationService,
    private dialogRef: MatDialogRef<DocumentViewDialogComponent>,
    @Inject(MAT_DIALOG_DATA) public data: DocumentViewDialogDataInterface
  ) {}

  ngOnInit() {
    try {
      this.dmsDocumentService
        .getDmsDocument({
          referenceNumber: this.data.documentNumber,
          minDateTime: this.data.minDateTime,
          maxDateTime: this.data.maxDateTime,
          docType: this.data.documentType,
        })
        .pipe(skip(1), take(1))
        .subscribe(
          (success) => {
            this.pdfBytes = success.file;
            this.docDtls.document = success.file;
            this.documentDetailsSubject.next(this.docDtls);
          },
          (error) => {
            this.docDtls.documentNotFound = true;
            this.docDtls.errorMessage = error;
            this.documentDetailsSubject.next(this.docDtls);
          }
        );
    } finally {
      this.notificationService.hideOverlayMessage();
    }
  }

  get zoomLevel() {
    return this.zoomControl ? this.zoomControl.value : 1;
  }
  set zoomLevel(value) {
    if (this.zoomControl) {
      this.zoomControl.setValue(value);
    }
  }

  callBackFn(event) {
    this.docDtls.currentPage = 1;
    this.docDtls.totalPages = event.numPages;
    this.documentDetailsSubject.next(this.docDtls);
  }

  navigate(direction: number) {
    if (
      (this.docDtls.currentPage > 1 && direction < 0) ||
      (this.docDtls.currentPage < this.docDtls.totalPages && direction > 0)
    ) {
      this.docDtls.currentPage += direction;
      this.documentDetailsSubject.next(this.docDtls);
    }
  }

  handleDownloadClicked(): () => void {
    return this.externalExporter.export.bind(this.externalExporter, this.pdfBytes);
  }
}

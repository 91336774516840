import { Component, Inject, OnInit } from '@angular/core';
import { MAT_DIALOG_DATA, MatDialogRef } from '@angular/material/dialog';

import { ReleaseInvoicesDialogDataInterface } from '@shared/models';

@Component({
  selector: 'app-release-invoices-dialog',
  templateUrl: './release-invoices-dialog.component.html',
  styleUrls: ['./release-invoices-dialog.component.scss'],
})
export class ReleaseInvoicesDialogComponent implements OnInit {
  invoicesToRelease: string;

  constructor(
    private dialogRef: MatDialogRef<ReleaseInvoicesDialogComponent>,
    @Inject(MAT_DIALOG_DATA) public data: ReleaseInvoicesDialogDataInterface
  ) {
    this.invoicesToRelease = data.invoicesToRelease > 1 ? `these ${data.invoicesToRelease} invoices` : 'one invoice';
  }

  ngOnInit() {}
}

import { NgModule } from '@angular/core';
import { CommonModule } from '@angular/common';
import { MatDialogModule } from '@angular/material/dialog';
import { FlexLayoutModule } from '@angular/flex-layout';
import { FormsModule, ReactiveFormsModule } from '@angular/forms';

import {
  XpoIconModule,
  XpoChipsModule,
  XpoDialogModule,
  XpoSelectModule,
  XpoCheckboxModule,
  XpoBusyLoaderModule,
  XpoConfirmDialogModule,
  XpoDownloadButtonModule,
  XpoStatusIndicatorModule,
} from '@xpo-ltl/ngx-ltl-core';
import { DocumentManagementApiService } from '@xpo-ltl/sdk-documentmanagement';

import { PdfViewerModule } from 'ng2-pdf-viewer';
import { AgGridModule } from 'ag-grid-angular';


import { PipeModule } from '@shared/pipes/pipe.module';
import { MaterialModule } from '@shared/material/material.module';

import { AddRfcDialogComponent } from './add-rfc-dialog/add-rfc-dialog.component';
import { ConfirmDialogComponent } from './confirm-dialog/confirm-dialog.component';
import { EditRfcDialogComponent } from './edit-rfc-dialog/edit-rfc-dialog.component';
import { BothBillDialogComponent } from './both-bill-dialog/both-bill-dialog.component';
import { RemoveRfcDialogComponent } from './remove-rfc-dialog/remove-rfc-dialog.component';
import { DocumentViewDialogComponent } from './document-view-dialog/document-view-dialog.component';
import { RemoveInvoicesDialogComponent } from './remove-invoices-dialog/remove-invoices-dialog.component';
import { ResendInvoicesDialogComponent } from './resend-invoices-dialog/resend-invoices-dialog.component';
import { ReleaseInvoicesDialogComponent } from './release-invoices-dialog/release-invoices-dialog.component';
import { InvoiceNotFoundDialogComponent } from './invoice-not-found-dialog/invoice-not-found-dialog.component';
import { ResendClosedInvoicesDialogComponent } from './resend-closed-invoices-dialog/resend-closed-invoices-dialog.component';

@NgModule({
  declarations: [
    AddRfcDialogComponent,
    ConfirmDialogComponent,
    EditRfcDialogComponent,
    BothBillDialogComponent,
    RemoveRfcDialogComponent,
    DocumentViewDialogComponent,
    RemoveInvoicesDialogComponent,
    ResendInvoicesDialogComponent,
    ReleaseInvoicesDialogComponent,
    InvoiceNotFoundDialogComponent,
    ResendClosedInvoicesDialogComponent,
  ],
  imports: [
    PipeModule,
    FormsModule,
    CommonModule,
    XpoIconModule,
    XpoChipsModule,
    MaterialModule,
    MatDialogModule,
    PdfViewerModule,
    XpoDialogModule,
    XpoSelectModule,
    FlexLayoutModule,
    XpoCheckboxModule,
    ReactiveFormsModule,
    XpoBusyLoaderModule,
    XpoConfirmDialogModule,
    XpoDownloadButtonModule,
    XpoStatusIndicatorModule,
    AgGridModule.withComponents([]),
  ],
  entryComponents: [
    AddRfcDialogComponent,
    ConfirmDialogComponent,
    EditRfcDialogComponent,
    BothBillDialogComponent,
    RemoveRfcDialogComponent,
    DocumentViewDialogComponent,
    RemoveInvoicesDialogComponent,
    ResendInvoicesDialogComponent,
    ReleaseInvoicesDialogComponent,
    InvoiceNotFoundDialogComponent,
    ResendClosedInvoicesDialogComponent
  ],
  providers: [
    DocumentManagementApiService
  ],
  exports: [
    AddRfcDialogComponent,
    ConfirmDialogComponent,
    EditRfcDialogComponent,
    BothBillDialogComponent,
    RemoveRfcDialogComponent,
    DocumentViewDialogComponent,
    RemoveInvoicesDialogComponent,
    ResendInvoicesDialogComponent,
    InvoiceNotFoundDialogComponent,
    ReleaseInvoicesDialogComponent,
    ResendClosedInvoicesDialogComponent
  ],
})
export class DialogsModule {}

import { Injectable, OnDestroy } from '@angular/core';
import { Subject } from 'rxjs';

@Injectable()
export class AppToolbarService implements OnDestroy {

  constructor() { }

  private alignmentSubject = new Subject<number>();
  alignment$ = this.alignmentSubject.asObservable();

  set alignment(leftPosition: number) {
    this.alignmentSubject.next(leftPosition);
  }

  ngOnDestroy(): void {
    this.alignmentSubject.next();
    this.alignmentSubject.complete();
  }
}

import { ChangeDetectorRef, Component, Input, NgZone, OnDestroy, OnInit } from '@angular/core';
import { MatSnackBarRef } from '@angular/material/snack-bar';

import { ConfigManagerService } from '@xpo-ltl/config-manager';
import { XpoNotificationTemplate, XpoSnackBar } from '@xpo-ltl/ngx-ltl-core';
import { NotificationMessage, NotificationService, NotificationTypeEnum } from '@xpo-ltl/data-api';

import { take, takeUntil } from 'rxjs/operators';
import { BehaviorSubject, Subject } from 'rxjs';

import { SnackbarStatus } from '@shared/enums';

import { ConfigManagerProperties } from '@shared/enums';

const DEFAULT_SNACKBAR_DURATION = 5000;

@Component({
  selector: 'app-notification',
  templateUrl: './notification.component.html',
  styleUrls: ['./notification.component.scss']
})
export class NotificationComponent implements OnInit, OnDestroy {
  private messageSubject = new BehaviorSubject<string>('');
  private showSubject = new BehaviorSubject<boolean>(false);

  message$ = this.messageSubject.asObservable();
  show$ = this.showSubject.asObservable();

  @Input() showProgressSpinner = true;

  private unsubscribe = new Subject<void>();
  private snackBarRef: MatSnackBarRef<XpoNotificationTemplate>;

  constructor(
    private zone: NgZone,
    private cdr: ChangeDetectorRef,
    private xpoSnackbar: XpoSnackBar,
    private notificationService: NotificationService,
    private configManagerService: ConfigManagerService
  ) { }

  ngOnInit() {
    this.notificationService
      .getSubscriber()
      .pipe(takeUntil(this.unsubscribe))
      .subscribe((message: NotificationMessage) => {
        try {
          this.zone.run(() => {
            if (message.type !== NotificationTypeEnum.ShowSnackbar) {
              this.messageSubject.next(message.text);
              this.showSubject.next(message.type === NotificationTypeEnum.ShowOverlay);
              this.cdr.detectChanges();
            } else {
              const actionHandlerLabel = message.snackbarConfig != null &&
                message.snackbarConfig.actionHandler != null &&
                message.snackbarConfig.actionHandler.actionLabel() != null
                ? message.snackbarConfig.actionHandler.actionLabel()
                : null;

              const status = message.snackbarConfig.status as SnackbarStatus;

              this.snackBarRef = this.openSnackBar(
                actionHandlerLabel,
                status,
                message.text
              );

              if (message.snackbarConfig != null && message.snackbarConfig.actionHandler != null) {
                if (message.snackbarConfig.actionHandler.onAction != null) {
                  this.snackBarRef
                    .onAction()
                    .pipe(take(1))
                    .subscribe(() => {
                      message.snackbarConfig.actionHandler.onAction();
                    });
                }
                if (message.snackbarConfig.actionHandler.afterOpened != null) {
                  this.snackBarRef
                    .afterOpened()
                    .pipe(take(1))
                    .subscribe(message.snackbarConfig.actionHandler.afterOpened);
                }
                if (message.snackbarConfig.actionHandler.afterDismissed != null) {
                  this.snackBarRef
                    .afterDismissed()
                    .pipe(take(1))
                    .subscribe(message.snackbarConfig.actionHandler.afterDismissed);
                }
              }
            }
          });
        } catch (error) {
          // todo: log
        }
      });
  }

  /**
   * Opens a snack bar (toast) message.
   */
  openSnackBar(
    message: string,
    status: SnackbarStatus,
    detailedMessage = ``,
    duration: number = DEFAULT_SNACKBAR_DURATION,
    action?: string,
    actionFunc?: Function
  ): MatSnackBarRef<XpoNotificationTemplate> {
    switch (status) {
      case SnackbarStatus.Success:
        duration = this.configManagerService.getSetting<number>(ConfigManagerProperties.successToastDuration);
        break;
      case SnackbarStatus.Error:
        duration = this.configManagerService.getSetting<number>(ConfigManagerProperties.errorToastDuration);
        break;
      case SnackbarStatus.Warn:
        duration = this.configManagerService.getSetting<number>(ConfigManagerProperties.warningToastDuration);
    }

    if (detailedMessage && !message) {
      message = detailedMessage;
      detailedMessage = '';
    }

    if (action) {
      return this.xpoSnackbar.open({
        message,
        detailedMessage,
        status,
        matConfig: {
          duration,
          verticalPosition: 'bottom',
          panelClass: `snackbar-${status}`,
        },
        linkAction: {
          message: action,
          function: actionFunc()
        }
      });
    }
    return this.xpoSnackbar.open({
      message,
      detailedMessage,
      status,
      matConfig: {
        duration,
        verticalPosition: 'bottom',
        panelClass: `snackbar-${status}`,
      },
    });
  }

  ngOnDestroy() {
    this.unsubscribe.next();
    this.unsubscribe.complete();
  }
}

import { Router } from '@angular/router';
import { Injectable, NgZone } from '@angular/core';
import { MatDialog } from '@angular/material/dialog';

import moment from 'moment';
import * as _ from 'lodash';
import { take } from 'rxjs/operators';
import { ReplaySubject, BehaviorSubject, Subject, Observable } from 'rxjs';

import {
  Invoice,
  InvoiceApiService,
  ListInvoiceTransmissionHistoryQuery,
  ListInvoiceTransmissionHistoryResp,
  ListInvoiceDetailByProPath,
  ListInvoiceTransmissionHistoryPath,
  ListInvoiceDetailByProResp,
} from '@xpo-ltl/sdk-invoice';
import { XpoLtlEmailService } from '@xpo-ltl/ngx-ltl';
import { ChargeToCd, User } from '@xpo-ltl/sdk-common';
import { ConditioningService } from '@xpo-ltl/common-services';
import { ConfigManagerService } from '@xpo-ltl/config-manager';

import { BothBillData, DocumentViewDialogDataInterface } from '@shared/models';

import { BothBillDialogComponent } from '@shared/dialogs/both-bill-dialog/both-bill-dialog.component';
import { DocumentViewDialogComponent } from '@shared/dialogs/document-view-dialog/document-view-dialog.component';
import { InvoiceNotFoundDialogComponent } from '@shared/dialogs/invoice-not-found-dialog/invoice-not-found-dialog.component';
import { ApplicationRoles } from '@shared/models/user';
import { ConfigManagerProperties } from '@shared/enums/config-manager-properties.enum';
import { ForceEDIPaper, InvoiceRoles, ReleaseRoles, ResendRoles, RfcReferenceRoles } from '@shared/enums/user-roles.enum';
import { DocType } from '@shared/enums/doc-type.enum';
import { RouterUriComponents } from '@shared/enums/router-uri-components.enum';

@Injectable({ providedIn: 'root' })
export class InvoiceAuditorService {
  isProduction: boolean = false;
  isProd: boolean = this.configManagerService.getSetting<boolean>(ConfigManagerProperties.isProd);
  private invoiceSubject: ReplaySubject<Invoice> = new ReplaySubject<Invoice>(1);
  invoice$ = this.invoiceSubject.asObservable();
  user: User;
  private rolesSubject = new Subject<string[]>();
  roles$ = this.rolesSubject.asObservable();
  invoicingRoles = {};
  invoicingRequiredRoles: ApplicationRoles;
  private _userRoles: string[];
  private invoiceDeliveryIdSubject = new BehaviorSubject<number>(0);
  invoiceDeliveryId$ = this.invoiceDeliveryIdSubject.asObservable();
  private documentViewDialogRef;
  private readonly removeDomainRegExp = /.*\//gm;

  private proList = new BehaviorSubject<string[]>([]);
  sendPros$ = this.proList.asObservable();

  get userRoles(): string[] {
    return this._userRoles;
  }

  set userRoles(roles: string[]) {
    this._userRoles = roles;
    this.rolesSubject.next(roles);
  }

  constructor(
    private ngZone: NgZone,
    private router: Router,
    private dialog: MatDialog,
    private emailService: XpoLtlEmailService,
    public invoiceApiService: InvoiceApiService,
    private conditioningService: ConditioningService,
    private configManagerService: ConfigManagerService
  ) {
    this.isProduction = this.configManagerService.getSetting<boolean>(ConfigManagerProperties.production);
  }

  isViewInvoiceTabVisible(roles: string[]): boolean {
    const testRoles = this.isProduction
      ? []
      : [
        InvoiceRoles.TstConwayDefaultTabigator,
        InvoiceRoles.TstConwayAdDefaultTabigator,
        InvoiceRoles.TstCtsAdDefaultTabigator,
        InvoiceRoles.TstCfiAdDefaultTabigator,
        InvoiceRoles.TstConwayAdInvoiceSpecialist,
        InvoiceRoles.TstCtsAdInvoiceSpecialist,
        InvoiceRoles.TstCfiAdInvoiceSpecialist,
        InvoiceRoles.TstConwayInvoiceAnalyst,
        InvoiceRoles.TstConwayAdInvoiceAnalyst,
        InvoiceRoles.TstCtsAdInvoiceAnalyst,
        InvoiceRoles.TstCfiAdInvoiceAnalyst,
        InvoiceRoles.TstConwayInvoiceExl,
        InvoiceRoles.TstConwayAdInvoiceExl,
        InvoiceRoles.TstCfiAdInvoiceExl,
        InvoiceRoles.TstConwayInvoiceMexico,
        InvoiceRoles.TstConwayAdInvoiceMexico,
        InvoiceRoles.TstCfiAdInvoiceMexico,
        InvoiceRoles.TstCtsAdInvoiceMexico,
        InvoiceRoles.TstInvoicePresentmentTibcoApp,
        InvoiceRoles.TstQAAppUse,
      ];

    const prodRoles = [
      InvoiceRoles.ProdConwayDefaultTabigator,
      InvoiceRoles.ProdConwayAdDefaultTabigator,
      InvoiceRoles.ProdCtsAdDefaultTabigator,
      InvoiceRoles.ProdCfiAdDefaultTabigator,
      InvoiceRoles.ProdConwayAdInvoiceSpecialist,
      InvoiceRoles.ProdCtsAdInvoiceSpecialist,
      InvoiceRoles.ProdCfiAdInvoiceSpecialist,
      InvoiceRoles.ProdConwayInvoiceAnalyst,
      InvoiceRoles.ProdConwayAdInvoiceAnalyst,
      InvoiceRoles.ProdCtsAdInvoiceAnalyst,
      InvoiceRoles.ProdCfiAdInvoiceAnalyst,
      InvoiceRoles.ProdConwayInvoiceExl,
      InvoiceRoles.ProdConwayAdInvoiceExl,
      InvoiceRoles.ProdCfiAdInvoiceExl,
      InvoiceRoles.ProdConwayInvoiceMexico,
      InvoiceRoles.ProdConwayAdInvoiceMexico,
      InvoiceRoles.ProdCfiAdInvoiceMexico,
      InvoiceRoles.ProdCtsAdInvoiceMexico,
      InvoiceRoles.ProdInvoicePresentmentTibcoApp,
    ];

    return this.hasRole(roles, [...prodRoles, ...testRoles]);
  }

  isReleaseInvoicesTabVisible(roles: string[]): boolean {
    const testRoles = this.isProduction ? []
    : [
      ReleaseRoles.TstConwayInvoiceSpecialist,
      ReleaseRoles.TstConwayAdInvoiceSpecialist,
      ReleaseRoles.TstConwayInvoiceExl,
      ReleaseRoles.TstConwayAdInvoiceExl,
      ReleaseRoles.TstCtsAdInvoiceSpecialist,
      ReleaseRoles.TstCtsAdInvoiceExl,
      ReleaseRoles.TstCtsAdInvoiceRfc,
      ReleaseRoles.TstCtsAdInvoiceMexico,
      ReleaseRoles.TstConwayInvoiceMexico,
      ReleaseRoles.TstConwayAdInvoiceMexico,
      ReleaseRoles.TstCfiAdInvoiceSpecialist,
      ReleaseRoles.TstCfiAdInvoiceExl,
      ReleaseRoles.TstCfiAdInvoiceMexico,
      ReleaseRoles.TstAppInvapiReleaseHelInvoice,
      ReleaseRoles.TstCfiAdInvoiceRfc,
      ReleaseRoles.TstConwayAdInvoiceRfc,
      ReleaseRoles.TstConwayInvoiceRfc,
    ];

    const prodRoles = [
      ReleaseRoles.ProdConwayInvoiceSpecialist,
      ReleaseRoles.ProdConwayAdInvoiceSpecialist,
      ReleaseRoles.ProdConwayInvoiceExl,
      ReleaseRoles.ProdConwayAdInvoiceExl,
      ReleaseRoles.ProdCtsAdInvoiceSpecialist,
      ReleaseRoles.ProdCtsAdInvoiceExl,
      ReleaseRoles.ProdCtsAdInvoiceRfc,
      ReleaseRoles.ProdCtsAdInvoiceMexico,
      ReleaseRoles.ProdConwayInvoiceMexico,
      ReleaseRoles.ProdConwayAdInvoiceMexico,
      ReleaseRoles.ProdCfiAdInvoiceSpecialist,
      ReleaseRoles.ProdCfiAdInvoiceExl,
      ReleaseRoles.ProdCfiAdInvoiceMexico,
      ReleaseRoles.ProdAppInvapiReleaseHelInvoice,
      ReleaseRoles.ProdCfiAdInvoiceRfc,
      ReleaseRoles.ProdConwayAdInvoiceRfc,
      ReleaseRoles.ProdConwayInvoiceRfc,
    ];

    return this.hasRole(roles, [
      ...prodRoles,
      ...testRoles,      
    ]);
  }

  isReleaseInvoicesTabHidden(roles: string[]): boolean {
    return !this.isReleaseInvoicesTabVisible(roles);
  }

  isResendInvoicesTabVisible(roles: string[]): boolean {
    const testRoles = this.isProduction ? [] : [
      InvoiceRoles.TstConwayInvoiceAnalyst,
      InvoiceRoles.TstConwayAdInvoiceAnalyst,
      ReleaseRoles.TstConwayInvoiceSpecialist,
      ReleaseRoles.TstConwayAdInvoiceSpecialist,
      ReleaseRoles.TstConwayInvoiceMexico,
      ReleaseRoles.TstConwayAdInvoiceMexico,
      InvoiceRoles.TstCtsAdInvoiceAnalyst,
      ReleaseRoles.TstCtsAdInvoiceSpecialist,
      ReleaseRoles.TstCtsAdInvoiceMexico,
      InvoiceRoles.TstCfiAdInvoiceAnalyst,
      ReleaseRoles.TstCfiAdInvoiceSpecialist,
      ReleaseRoles.TstCfiAdInvoiceMexico,
    ];

    const prodRoles = [
      InvoiceRoles.ProdConwayInvoiceAnalyst,
      InvoiceRoles.ProdConwayAdInvoiceAnalyst,
      ReleaseRoles.ProdConwayInvoiceSpecialist,
      ReleaseRoles.ProdConwayAdInvoiceSpecialist,
      ReleaseRoles.ProdConwayInvoiceMexico,
      ReleaseRoles.ProdConwayAdInvoiceMexico,
      InvoiceRoles.ProdCtsAdInvoiceAnalyst,
      ReleaseRoles.ProdCtsAdInvoiceSpecialist,
      ReleaseRoles.ProdCtsAdInvoiceMexico,
      InvoiceRoles.ProdCfiAdInvoiceAnalyst,
      ReleaseRoles.ProdCfiAdInvoiceSpecialist,
      ReleaseRoles.ProdCfiAdInvoiceMexico,
      ResendRoles.ProdAppInvapiResendInvoice,
    ];

    return this.hasRole(roles, [
      ...prodRoles,
      ...testRoles,
    ]);
  }

  isRFCManagementTabVisible(roles: string[]): boolean {
    const testRoles = this.isProduction ? [] : [
      InvoiceRoles.TstConwayInvoiceMexico,
      InvoiceRoles.TstConwayAdInvoiceMexico,
      InvoiceRoles.TstCfiAdInvoiceMexico,
      ReleaseRoles.TstCtsAdInvoiceMexico,
      ReleaseRoles.TstConwayInvoiceRfc,
      ReleaseRoles.TstConwayAdInvoiceRfc,
      ReleaseRoles.TstCfiAdInvoiceRfc,
      ReleaseRoles.TstCtsAdInvoiceRfc,
    ];

    const prodRoles = [
      ReleaseRoles.ProdConwayInvoiceMexico,
      ReleaseRoles.ProdConwayAdInvoiceMexico,
      ReleaseRoles.ProdCfiAdInvoiceMexico,
      ReleaseRoles.ProdCtsAdInvoiceMexico,
      ReleaseRoles.ProdConwayInvoiceRfc,
      ReleaseRoles.ProdConwayAdInvoiceRfc,
      ReleaseRoles.ProdCfiAdInvoiceRfc,
      ReleaseRoles.ProdCtsAdInvoiceRfc,
      RfcReferenceRoles.ProdSpeedyInvoicingApp,
      RfcReferenceRoles.ProdAppBillOfLadingWebApp,
    ];

    return this.hasRole(roles, [
      ...prodRoles,
      ...testRoles,
    ]);
  }

  isForceEDIPaperVisible(roles: string[]): boolean {
    const testRoles = this.isProduction ? [] : [
      ReleaseRoles.TstConwayInvoiceSpecialist,
      ReleaseRoles.TstConwayAdInvoiceSpecialist,
      InvoiceRoles.TstCtsAdInvoiceSpecialist,
      InvoiceRoles.TstCfiAdInvoiceSpecialist,
      ForceEDIPaper.TestInvoiceCreationTibcoApp,
    ];

    const prodRoles = [
      ReleaseRoles.ProdConwayInvoiceSpecialist,
      ReleaseRoles.ProdConwayAdInvoiceSpecialist,
      ReleaseRoles.ProdCtsAdInvoiceSpecialist,
      ReleaseRoles.ProdCfiAdInvoiceSpecialist,
      ForceEDIPaper.ProdInvoiceCreationTibcoApp,
      ForceEDIPaper.ProdInvoicePresentmentTibcoApp,
      ForceEDIPaper.ProdnvapiForceEDIPaper,
    ];

    return this.hasRole(roles, [
      ...prodRoles,      
      ...testRoles,
    ]);
  }

  isRFCReadOnlyMode(roles: string[]): boolean {
    const testRoles = this.isProduction ? [] : [
      ReleaseRoles.TstConwayInvoiceRfc,
      ReleaseRoles.TstConwayAdInvoiceRfc,
      ReleaseRoles.TstCfiAdInvoiceRfc,
      ReleaseRoles.TstCtsAdInvoiceRfc,
    ];

    const prodRoles = [
      ReleaseRoles.ProdConwayInvoiceRfc,
      ReleaseRoles.ProdConwayAdInvoiceRfc,
      ReleaseRoles.ProdCfiAdInvoiceRfc,
      ReleaseRoles.ProdCtsAdInvoiceRfc,
      RfcReferenceRoles.ProdSpeedyInvoicingApp,
      RfcReferenceRoles.ProdAppBillOfLadingWebApp,
    ];

    return this.hasRole(roles, [
      ...prodRoles,      
      ...testRoles,
    ]);
  }

  isAuthorizedUser(roles: string[]): boolean {
    return (
      this.isViewInvoiceTabVisible(roles) ||
      this.isReleaseInvoicesTabVisible(roles) ||
      this.isResendInvoicesTabVisible(roles) ||
      this.isRFCManagementTabVisible(roles) ||
      this.isForceEDIPaperVisible(roles)
    );
  }

  setCurrentInvoice(invoice: Invoice) {
    this.invoiceSubject.next(invoice);
  }

  loadDocument(invoice: Invoice, docType: DocType) {
    if (!this.documentViewDialogRef) {
      const data = new DocumentViewDialogDataInterface();
      data.documentType = docType;
      data.documentNumber = invoice.invoiceHeader.dmsInvoiceImageUrl;

      if (docType !== DocType.InvoicePdf) {
        data.documentNumber = this.conditioningService.conditionProNumber(invoice.invoiceClobData.proNbr, 9);
        data.minDateTime = moment(invoice.invoiceDetail.shipmentInfo.pickupDate)
          .subtract(5, 'days')
          .toDate();
        data.maxDateTime = moment(invoice.invoiceDetail.shipmentInfo.pickupDate)
          .add(18, 'months')
          .toDate();
      }

      this.documentViewDialogRef = this.dialog
        .open(DocumentViewDialogComponent, { data: data })
        .afterClosed()
        .pipe(take(1))
        .subscribe(() => {
          this.documentViewDialogRef = null;
        });
    }
  }

  sendEmailToFixPro(formattedPro: string): Observable<boolean> {
    const subject = new Subject<boolean>();

    this.emailService
      .sendEmail(
        this.user.givenName,
        this.user.emailAddress,
        this.configManagerService.getSetting<string>(ConfigManagerProperties.supportAddress),
        _.trim(
          `${this.configManagerService.getSetting<string>(
            ConfigManagerProperties.region
          )} Please fix data for PRO ${formattedPro} as soon as possible`
        ),
        _.trim(
          `${this.configManagerService.getSetting<string>(
            ConfigManagerProperties.region
          )} Please fix data for PRO ${formattedPro} as soon as possible.`
        )
      )
      .pipe(take(1))
      .subscribe(() => {
        subject.next();
        subject.complete();
      });

    return subject.asObservable();
  }

  handleInvoiceSearchAndNavigate(
    pro: string,
    forceChargeToCd?: ChargeToCd,
    invoicePdfUrl?: string
  ): Observable<boolean> {
    const pathParams = new ListInvoiceDetailByProPath();
    pathParams.proNbr = this.conditioningService.conditionProNumber(pro, 11);

    const returnValue = new Subject<boolean>();

    const done = (success: boolean) => {
      returnValue.next(success);
      returnValue.complete();
    };

    const setInvoiceAndNavigate = (invoice: Invoice) => {
      if (invoicePdfUrl) {
        invoice.invoiceHeader.dmsInvoiceImageUrl = invoicePdfUrl;
      }
      this.setCurrentInvoice(invoice);
      this.ngZone.run(() => {
        this.router.navigate([`${RouterUriComponents.INVOICING_MAIN_PAGE}`]).then(() => {
          done(true);
        });
      });
    };

    this.invoiceApiService
      .listInvoiceDetailByPro(pathParams, {
        loadingOverlayMessage: 'Searching...',
        loadingOverlayEnabled: true,
      })
      .pipe(take(1))
      .subscribe(
        (response: ListInvoiceDetailByProResp) => {
          if (response && response.invoice) {
            if (response.invoice.length > 1) {
              if (response.invoice[0].invoiceHeader.bothBillInd && response.invoice[1].invoiceHeader.bothBillInd) {
                if (forceChargeToCd) {
                  setInvoiceAndNavigate(
                    response.invoice.slice(0, 2).find((inv) => inv.invoiceHeader.chargeToCd === forceChargeToCd)
                  );
                } else {
                  const isFirstBothBill = !!response.invoice[0].invoiceHeader.bothBillInd;
                  const isSecondBothBill = !!response.invoice[1].invoiceHeader.bothBillInd;
                  const areBothSameType =
                    isFirstBothBill &&
                    isSecondBothBill &&
                    response.invoice[0].invoiceHeader.chargeToCd === response.invoice[1].invoiceHeader.chargeToCd;

                  if (!areBothSameType) {
                    this.bothBillDialog(pathParams.proNbr, response.invoice.slice(0, 2)).subscribe((result) => {
                      if (result) {
                        setInvoiceAndNavigate(result);
                      } else {
                        done(false);
                      }
                    });
                  } else {
                    setInvoiceAndNavigate(response.invoice[0]);
                  }
                }
              } else {
                const invoices = response.invoice;
                setInvoiceAndNavigate(invoices[0]);
              }
            } else {
              setInvoiceAndNavigate(response.invoice[0]);
            }
          } else {
            done(false);
          }
        },
        (error) => {
          const data = { error };
          const dialogRef = this.dialog.open(InvoiceNotFoundDialogComponent, { data });
          dialogRef
            .afterClosed()
            .pipe(take(1))
            .subscribe(() => {
              done(false);
            });
        }
      );
    return returnValue.asObservable();
  }

  bothBillDialog(proNbr: string, invoices: Array<Invoice>): Observable<Invoice> {
    const data = new BothBillData(proNbr, invoices);
    const dialogRef = this.dialog.open(BothBillDialogComponent, { data });
    return dialogRef.afterClosed();
  }

  listInvoiceTransmissionHistoryWrapper(pro: string): Observable<ListInvoiceTransmissionHistoryResp> {
    const pathParams = new ListInvoiceTransmissionHistoryPath();
    pathParams.proNbr = this.conditioningService.conditionProNumber(pro, 11);

    const queryParams = new ListInvoiceTransmissionHistoryQuery();

    return this.invoiceApiService.listInvoiceTransmissionHistory(pathParams, queryParams);
  }

  setInvoiceDeliveryId(invoiceDeliveryId: number): void {
    this.invoiceDeliveryIdSubject.next(invoiceDeliveryId);
  }

  sendPros(pros: string[]): void {
    this.proList.next(pros);
  }

  private hasRole(userRoles: string[], roleOf: any): boolean {
    const roleOfArray = _.castArray(roleOf);
    const results = _.intersectionBy(userRoles, roleOfArray, (value: string) => value.toUpperCase());
    return (results.length > 0 ? true : false);
  }
}

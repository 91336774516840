import { NgModule } from '@angular/core';
import { CommonModule } from '@angular/common';
import { FlexLayoutModule } from '@angular/flex-layout';
import { FormsModule, ReactiveFormsModule } from '@angular/forms';

import { PipeModule } from '@shared/pipes/pipe.module';
import { DialogsModule } from '@shared/dialogs/dialogs.module';
import { MaterialModule } from '@shared/material/material.module';

import { EmailValidatorDirective } from '@shared/validators/email-validator/email.validator';
import { RemoteMadcodeValidator } from '@shared/validators/madcode-validator/madcode.validator';
import { PhoneFormatValidatorDirective } from '@shared/validators/phone-validator/phone.validator';

@NgModule({
  declarations: [EmailValidatorDirective, PhoneFormatValidatorDirective],
  imports: [
    PipeModule,
    FormsModule,
    CommonModule,
    DialogsModule,
    MaterialModule,
    FlexLayoutModule,
    ReactiveFormsModule,
  ],
  exports: [
    PipeModule,
    FormsModule,
    CommonModule,
    DialogsModule,
    MaterialModule,
    FlexLayoutModule,
    ReactiveFormsModule,
    EmailValidatorDirective,
    PhoneFormatValidatorDirective,
  ],
  providers: [RemoteMadcodeValidator]
})
export class SharedModule {}

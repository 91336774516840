import { Pipe, PipeTransform } from '@angular/core';
import { InvoiceShipmentParty } from '@xpo-ltl/sdk-invoice';

@Pipe({
  name: 'invoiceShipmentPartyCityStateZip',
})
export class InvoiceShipmentPartyCityStateZipPipe implements PipeTransform {
  transform(value: InvoiceShipmentParty, args?: any): string {
    let formattedValue = `${value.city}, ${value.stateCd} ${value.postalCd}`;
    if (value.zip4RestUs) {
      formattedValue = `${formattedValue}-${value.zip4RestUs}`;
    }
    if (value.countryCd) {
      formattedValue = `${formattedValue}, ${value.countryCd}`;
    }
    return formattedValue;
  }
}

import { MatCheckbox } from '@angular/material/checkbox';
import { FormBuilder, FormGroup, Validators } from '@angular/forms';
import { MAT_DIALOG_DATA, MatDialogRef } from '@angular/material/dialog';
import { ChangeDetectorRef, Component, Inject, OnDestroy, OnInit, ViewChild, ViewEncapsulation } from '@angular/core';

import { catchError, map, take, takeUntil } from 'rxjs/operators';
import { forkJoin, Observable, of, Subject, throwError } from 'rxjs';
import { get as _get, maxBy as _maxBy, size as _size } from 'lodash';

import { ConfigManagerService } from '@xpo-ltl/config-manager';
import { ConditioningService, FormatValidationService } from '@xpo-ltl/common-services';
import { DmsDocTypeCd, InvoiceModeCd, InvoicePresentationFormatCd } from '@xpo-ltl/sdk-common';
import { AcctBasicDetails, CustomerApiService, GetAcctBasicDetailsRqst } from '@xpo-ltl/sdk-customer';


import {
  DocType,
  Countries,
  FileOptions,
  AttachmentLabels,
  AttachmentAsOptions,
  ConfigManagerProperties,
  ResendInvoiceFormActiveState,
  ResendInvoiceDialogFormFields,
} from '@shared/enums';

import { ResendInvoicesDialogDataInterface, ResendPreferences } from '@shared/models';

import { dynamicValidator } from '@shared/validators/dynamic-validator/dynamic-validator';
import { phoneFormatValidator } from '@shared/validators/phone-validator/phone.validator';
import { commaSeparatedEmail } from '@shared/validators/comma-separated-email-validator/comma-separated-email.validator';

import { DmsApiService } from '@shared/services/dms-api/dms-api.service';
import { InvoiceAuditorService } from '@shared/services/invoice-auditor/invoice-auditor.service';

@Component({
  selector: 'app-resend-invoices-dialog',
  templateUrl: './resend-invoices-dialog.component.html',
  styleUrls: ['./resend-invoices-dialog.component.scss'],
  encapsulation: ViewEncapsulation.None,
})
export class ResendInvoicesDialogComponent implements OnInit, OnDestroy {

  private readonly EMAIL_SUBJECT_DEFAULT_VALUE = 'XPO Logistics Invoices Retransmit Attached (Format)';
  private readonly PAPER_MAIL_LABEL = 'Paper/Mail';
  private unsubscriber = new Subject<void>();
  private invoiceModeCd: InvoiceModeCd;

  resendFormFields = ResendInvoiceDialogFormFields;
  attachmentAsOptions = AttachmentAsOptions;
  attachments = [];
  type: string;
  invoices: any[];
  maxSize: number;
  maxResendInvoicesPerEmail: number;
  fg: FormGroup;
  errorMessage: string;
  emailSubjectHasChanged = false;
  customer: AcctBasicDetails;

  countries = [Countries.UNITED_STATES, Countries.CANADA];
  states = [];
  fileTypes = [
    DocType.BillOfLading,
    DocType.DeliveryReceipt,
    DocType.InvoicePdf,
    DocType.LetterOfAuthorization,
    DocType.NmfcClassInspectionCertificate,
    DocType.WeightInspectionCertificate
  ];
  existingTypes: any[] = [];

  activePanelStates = ResendInvoiceFormActiveState;
  activePanelState = ResendInvoiceFormActiveState.NO_PANEL;

  totalSize = 0;
  showErrors = false;
  showTotalSize = false;
  isLoadingFiles: boolean;
  availableInvoices: any[] = [];
  availableFiles: any[] = [];
  areAllSelected = false;
  showRightPanel = false;
  isPdfSelected = false;
  isFileSelected = false;
  fileSizeExceeded = false;
  maxInvoicesExceeded = false;
  fileOptions: any[] = [];
  fileOptionsValues = {
    [FileOptions.AddCoverPage]: true,
    [FileOptions.OneInvoicePerEmail]: true,
  };
  singleFileOptions: any[] = [];
  singleFileOptionsValues = {
    [FileOptions.RenameFilename]: true,
  };
  isIndeterminate = false;
  @ViewChild('allSelected') private allSelected: MatCheckbox;

  constructor(
    private cdr: ChangeDetectorRef,
    private formBuilder: FormBuilder,
    private dmsDocumentService: DmsApiService,
    private customerService: CustomerApiService,
    private conditioningService: ConditioningService,
    private configManagerService: ConfigManagerService,
    private invoiceAuditorService: InvoiceAuditorService,
    private formatValidationService: FormatValidationService,
    private dialogRef: MatDialogRef<ResendInvoicesDialogComponent>,
    @Inject(MAT_DIALOG_DATA) public data: ResendInvoicesDialogDataInterface,
  ) {
    this.type = data.type;
    this.invoices = data.invoices || [];
  }

  get f() {
    return this.fg && this.fg.controls;
  }

  get filesFormGroup(): FormGroup {
    return this.f && <FormGroup>this.f[this.resendFormFields.ATTACHMENT_FILES];
  }

  get optionsFormGroup(): FormGroup {
    return this.f && <FormGroup>this.f[this.resendFormFields.FILE_OPTIONS];
  }

  get filesFormGroupSelectedIds(): string[] {
    const ids: string[] = [];
    if (!this.filesFormGroup) {
      return ids;
    }
    for (const key in this.filesFormGroup.controls) {
      if (this.filesFormGroup.controls[key].value) {
        ids.push(key);
      }
    }
    return ids;
  }

  get optionsFormGroupSelectedIds(): string[] {
    const ids: string[] = [];
    if (!this.optionsFormGroup) {
      return ids;
    }
    for (const key in this.optionsFormGroup.controls) {
      if (this.optionsFormGroup.controls[key].value) {
        ids.push(key);
      }
    }
    return ids;
  }

  get shouldShowFileSize(): boolean {
    const oneInvoicePerEmailField =  this.optionsFormGroup &&
      this.optionsFormGroup.controls[FileOptions.OneInvoicePerEmail];
    const attachAsField =  this.f && this.f[this.resendFormFields.ATTACHMENT_AS];
    const isOneInvoicePerEmailChecked = oneInvoicePerEmailField && oneInvoicePerEmailField.value;
    const isLinkSelected = attachAsField && attachAsField.value === this.attachmentAsOptions.Link;
    return !this.fileSizeExceeded &&
      !this.maxInvoicesExceeded &&
      !isLinkSelected &&
      this.type === InvoiceModeCd.EMAIL &&
      this.totalSize > 0 &&
      this.showTotalSize &&
      !isOneInvoicePerEmailChecked;
  }

  get shouldShowError(): boolean {
    return this.showErrors && (this.fileSizeExceeded || this.maxInvoicesExceeded);
  }

  get isMultiple(): boolean {
    return this.data.isMultiple;
  }

  hasError(error: string, field: ResendInvoiceDialogFormFields): boolean {
    return this.fg.get(field).hasError(error) && !this.fg.get(field).untouched;
  }

  ngOnInit() {
    this.maxSize = this.configManagerService.getSetting(ConfigManagerProperties.maxResendAttachmentSize);
    this.maxResendInvoicesPerEmail = this.configManagerService.getSetting(
      ConfigManagerProperties.maxResendInvoicesPerEmail);

    this.fg = this.formBuilder.group({
      [ResendInvoiceDialogFormFields.ATTACHMENT_FORMAT]: ['', Validators.required],
      [ResendInvoiceDialogFormFields.COMPANY_NAME]: [
        '',
        dynamicValidator(
          () => this.activePanelState === ResendInvoiceFormActiveState.NAME_ADDRESS,
          Validators.required
        ),
      ],
      [ResendInvoiceDialogFormFields.ADDRESS]: [
        '',
        dynamicValidator(
          () => this.activePanelState === ResendInvoiceFormActiveState.NAME_ADDRESS,
          Validators.required
        ),
      ],
      [ResendInvoiceDialogFormFields.CITY]: [
        '',
        dynamicValidator(
          () => this.activePanelState === ResendInvoiceFormActiveState.NAME_ADDRESS,
          Validators.required
        ),
      ],
      [ResendInvoiceDialogFormFields.STATE]: [
        '',
        dynamicValidator(
          () => this.activePanelState === ResendInvoiceFormActiveState.NAME_ADDRESS,
          Validators.required
        ),
      ],
      [ResendInvoiceDialogFormFields.ZIP_CODE]: [
        '',
        dynamicValidator(
          () => this.activePanelState === ResendInvoiceFormActiveState.NAME_ADDRESS,
          Validators.required
        ),
      ],
      [ResendInvoiceDialogFormFields.COUNTRY]: [
        '',
        dynamicValidator(
          () => this.activePanelState === ResendInvoiceFormActiveState.NAME_ADDRESS,
          Validators.required
        ),
      ],
      [ResendInvoiceDialogFormFields.EMAIL_ADDRESS]: [
        '',
        {
          validators: [
            dynamicValidator(
              () => this.activePanelState === ResendInvoiceFormActiveState.EMAIL_ADDRESS,
              Validators.required
            ),
            dynamicValidator(
              () => this.activePanelState === ResendInvoiceFormActiveState.EMAIL_ADDRESS,
              commaSeparatedEmail()
            )
          ],
        }
      ],
      [ResendInvoiceDialogFormFields.EMAIL_SUBJECT]: [
        '',
        [
          dynamicValidator(
            () => this.activePanelState === ResendInvoiceFormActiveState.EMAIL_ADDRESS,
            Validators.required
          ),
          dynamicValidator(
            () => this.activePanelState === ResendInvoiceFormActiveState.EMAIL_ADDRESS,
            Validators.maxLength(78)
          ),
          dynamicValidator(
            () => this.activePanelState === ResendInvoiceFormActiveState.EMAIL_ADDRESS,
            Validators.pattern('^[A-Za-z0-9()|@#$%&/=+¡!"\'·*{}:;-_?¿ ]+$')
          ),
        ],
      ],
      [ResendInvoiceDialogFormFields.ATTACHMENT_AS]: [],
      [ResendInvoiceDialogFormFields.FAX_NUMBER]: [
        '',
        [
          dynamicValidator(() => this.activePanelState === ResendInvoiceFormActiveState.FAX, Validators.required),
          dynamicValidator(() => this.activePanelState === ResendInvoiceFormActiveState.FAX, phoneFormatValidator()),
        ],
      ],
    });

    this.setForm();
    this.onValueChanges();
  }

  setForm() {
    switch (this.type) {
      case InvoiceModeCd.EDI:
        this.attachments = [AttachmentLabels.INDIVIDUAL];
        this.activePanelState = ResendInvoiceFormActiveState.NO_PANEL;
        this.invoiceModeCd = InvoiceModeCd.EDI;
        break;
      case InvoiceModeCd.EDI_TRANSFORMATION:
        this.attachments = [AttachmentLabels.INDIVIDUAL];
        this.activePanelState = ResendInvoiceFormActiveState.NO_PANEL;
        this.invoiceModeCd = InvoiceModeCd.EDI_TRANSFORMATION;
        break;
      case InvoiceModeCd.EMAIL:
        this.attachments = [
          AttachmentLabels.PDF,
          AttachmentLabels.CSV,
          AttachmentLabels.CSV_WITH_ACCESSORIAL,
          AttachmentLabels.XML,
        ];
        this.activePanelState = ResendInvoiceFormActiveState.EMAIL_ADDRESS;
        this.invoiceModeCd = InvoiceModeCd.EMAIL;
        this.fg
          .get(ResendInvoiceDialogFormFields.EMAIL_ADDRESS)
          .setValue(this.invoiceAuditorService.user.emailAddress);
          this.fg
          .get(ResendInvoiceDialogFormFields.EMAIL_SUBJECT)
          .setValue(this.EMAIL_SUBJECT_DEFAULT_VALUE);
        this.fg.get(ResendInvoiceDialogFormFields.EMAIL_ADDRESS).markAsTouched();
        this.showTotalSize = true;
        break;
      case InvoiceModeCd.FAX:
        this.attachments = [AttachmentLabels.XML];
        this.activePanelState = ResendInvoiceFormActiveState.FAX;
        this.invoiceModeCd = InvoiceModeCd.FAX;
        break;
      case InvoiceModeCd.FTP:
        this.attachments = [
          AttachmentLabels.PDF,
          AttachmentLabels.CSV,
          AttachmentLabels.CSV_WITH_ACCESSORIAL,
          AttachmentLabels.XML,
        ];
        this.activePanelState = ResendInvoiceFormActiveState.NO_PANEL;
        this.invoiceModeCd = InvoiceModeCd.FTP;
        break;
      case this.PAPER_MAIL_LABEL:
        this.attachments = [AttachmentLabels.PAPER];
        this.activePanelState = ResendInvoiceFormActiveState.NAME_ADDRESS;
        this.invoiceModeCd = InvoiceModeCd.PAPER;
        let obs$: Observable<AcctBasicDetails>;

        const sendTos = Array.from(new Set(this.invoices.map((item: any) => item.sendTo)));
        const isOnlyOneBillToSelected = sendTos.length <= 1;

        if (isOnlyOneBillToSelected) {
          const madCode = this.invoices[0].sendTo;
          obs$ = this.lookupCustByMadCode(madCode);
        } else {
          obs$ = of(null);
        }
        obs$.subscribe(customer => {
          if (customer && customer.acctIdInfo && customer.acctNameAddr) {
            this.fg.get(ResendInvoiceDialogFormFields.COMPANY_NAME).setValue(customer.acctIdInfo.acctName ||
              customer.acctNameAddr.name);
            this.fg.get(ResendInvoiceDialogFormFields.ADDRESS).setValue(customer.acctNameAddr.addressLine1);
            this.fg.get(ResendInvoiceDialogFormFields.CITY).setValue(customer.acctNameAddr.cityName);
            this.fg.get(ResendInvoiceDialogFormFields.STATE).setValue(customer.acctNameAddr.stateCd);
            this.fg.get(ResendInvoiceDialogFormFields.ZIP_CODE).setValue(customer.acctNameAddr.postalCd);
            this.fg.get(ResendInvoiceDialogFormFields.COUNTRY).setValue(customer.acctNameAddr.countryCd);
          }
        });
        break;
    }

    this.fg.get(ResendInvoiceDialogFormFields.ATTACHMENT_FORMAT).setValue('', { emitEvent: false });
    this.fg.get(ResendInvoiceDialogFormFields.ATTACHMENT_FORMAT).markAsUntouched();
    this.fg.get(ResendInvoiceDialogFormFields.ATTACHMENT_FORMAT).markAsPristine();

    Object.keys(this.fg.controls).forEach((key) => {
      this.fg.get(key).updateValueAndValidity({ emitEvent: false });
    });
    this.fg.updateValueAndValidity();
  }

  togglePerOne(event) {

    const checked = event.checked;

    if (!checked && this.allSelected.checked) {
      this.allSelected.checked = false;
      this.isIndeterminate = true;
    }

    const filesFieldValue = Object.entries(this.filesFormGroup.value).filter(file => Boolean(file[1]));

    if (filesFieldValue.length === this.existingTypes.length) {
      this.allSelected.checked = true;
      this.isIndeterminate = false;
    } else if (!filesFieldValue.length) {
      this.isIndeterminate = false;
    } else {
      this.isIndeterminate = true;
    }
  }

  toggleAllSelection() {
    this.isIndeterminate = false;
    const isChecked = this.allSelected.checked;
    Object.keys(this.filesFormGroup.controls).forEach((field) => {
      this.filesFormGroup.get(field).setValue(isChecked);
    });
  }

  getAverageInvoicePdf(): Observable<any> {

    if (!this.invoices.length) {
      return throwError(null);
    }

    const obs$ = {};

    let i = 0;
    this.invoices.forEach(item => {
      const alreadyExists = !!obs$[`${item.pro}-${i}`];
      const key = alreadyExists ? `${item.pro}-${++i}` : `${item.pro}-${i}`;
      obs$[key] = of(this.getAverageFromConfig(DocType.InvoicePdf));
      i = 0;
    });

    const keys = Object.keys(obs$);
    if (!keys.length) {
      return of(null);
    }

    return forkJoin(obs$).pipe(
      takeUntil(this.unsubscriber),
      catchError((error) => {
        return of(undefined);
      })
    );
  }

  /*
    Temporarily unused
  */
  getInvoicePdf(): Observable<any> {

    if (!this.invoices.length) {
      return throwError(null);
    }

    const obs$ = {};

    this.invoices.forEach(item => {
      if (item.dmsInvoiceImageUrl) {
        obs$[item.pro] = this.dmsDocumentService.hasDmsDocument(
          { referenceNumber: item.dmsInvoiceImageUrl, docType: DocType.InvoicePdf });
      }
    });

    const keys = Object.keys(obs$);
    if (!keys.length) {
      return of(null);
    }

    return forkJoin(obs$).pipe(
      takeUntil(this.unsubscriber),
      catchError((error) => {
        return of(undefined);
      })
    );
  }

  getAverageAvailableDocuments(types: DocType[] = []): Observable<any> {

    const imageTypes = [
      `${DocType.BillOfLading}`,
      `${DocType.DeliveryReceipt}`,
      `${DocType.LetterOfAuthorization}`,
      `${DocType.WeightInspectionCertificate}`,
      ...types
    ];

    if (!this.invoices.length) {
      return throwError(null);
    }

    if (this.invoices.length) {
      const obs$ = {};

      let i = 0;
      this.invoices.forEach(item => {
        const alreadyExists = !!obs$[`${item.pro}-${i}`];
        const key = alreadyExists ? `${item.pro}-${++i}` : `${item.pro}-${i}`;
        obs$[key] = of(imageTypes);
        i = 0;
      });

      return forkJoin(obs$).pipe(
        takeUntil(this.unsubscriber),
        catchError((error) => {
          return of(undefined);
        })
      );
    } else {
      return of(true);
    }
  }

  /*
    Temporarily unused
  */
  getAvailableDocuments(types: DocType[] = []): Observable<any> {

    const imageTypes = [
      `${DocType.BillOfLading}`,
      `${DocType.DeliveryReceipt}`,
      `${DocType.LetterOfAuthorization}`,
      `${DocType.WeightInspectionCertificate}`,
      ...types
    ];

    if (!this.invoices.length) {
      return throwError(null);
    }

    if (this.invoices.length) {
      const obs$ = {};
      this.invoices.forEach(item => {
        obs$[item.pro] = this.dmsDocumentService.hasImagingDocument(item.pro, imageTypes);
      });
      return forkJoin(obs$).pipe(
        takeUntil(this.unsubscriber),
        catchError((error) => {
          return of(undefined);
        })
      );
    } else {
      return of(true);
    }

  }

  onValueChanges(): void {
    this.fg.get(ResendInvoiceDialogFormFields.ATTACHMENT_FORMAT).valueChanges.subscribe(res => {
      this.showErrors = true;
      this.isPdfSelected = false;
      this.fileSizeExceeded = false;
      this.showRightPanel = false;
      this.isFileSelected = false;
      const attachmentAsField = this.fg.get(ResendInvoiceDialogFormFields.ATTACHMENT_AS);
      const fileOptionsField = this.fg.get(ResendInvoiceDialogFormFields.FILE_OPTIONS);

      if (fileOptionsField) {
        this.resetOptions(fileOptionsField);
      }

      if (this.activePanelState === ResendInvoiceFormActiveState.EMAIL_ADDRESS) {
        const previousValue =  this.fg.get(ResendInvoiceDialogFormFields.EMAIL_SUBJECT).value;
        const expression = /\(([^()]*)\)$/;
        let newValue = `(${res})`;
        const matches = previousValue.match(expression);
        if (matches && !this.emailSubjectHasChanged) {
          newValue = previousValue.replace(expression, newValue);
        } else {
          newValue = `${previousValue}`;
        }
        this.fg.get(ResendInvoiceDialogFormFields.EMAIL_SUBJECT).setValue(newValue);
      }

      if ((this.type === InvoiceModeCd.EMAIL || this.type === InvoiceModeCd.FTP) &&
      (res.includes(InvoicePresentationFormatCd.PDF))) {
        this.isLoadingFiles = true;
        forkJoin({
          invoices: this.getAverageInvoicePdf(),
          attachments: this.getAverageAvailableDocuments([DocType.Customs, DocType.NmfcClassInspectionCertificate])
        })
        .subscribe(({ invoices, attachments }) => {
          if (invoices) {
            this.setAverageOfInvoices(invoices);
          }
          if (attachments) {
            this.setAverageOfAvailableFiles(attachments);
          }
        }, _err => {
          this.isLoadingFiles = false;
          if (this.invoices.length > 1) {
            this.setFileOptions(this.fileOptionsValues);
          } else {
            this.setFileOptions(this.singleFileOptionsValues);
          }
          this.isPdfSelected = (this.type === InvoiceModeCd.EMAIL);
          attachmentAsField.setValue(AttachmentAsOptions.File);
          attachmentAsField.setValidators(Validators.required);
          attachmentAsField.markAsTouched();
          this.cdr.detectChanges();
        }, () => {
          this.isLoadingFiles = false;
          if (this.invoices.length > 1) {
            this.setFileOptions(this.fileOptionsValues);
          } else {
            this.setFileOptions(this.singleFileOptionsValues);
          }
          this.isPdfSelected = (this.type === InvoiceModeCd.EMAIL);
          attachmentAsField.setValue(AttachmentAsOptions.File);
          attachmentAsField.setValidators(Validators.required);
          attachmentAsField.markAsTouched();
          this.cdr.detectChanges();
        });
      } else {
        attachmentAsField.setValue(AttachmentAsOptions.File);
        attachmentAsField.setValidators(null);
        const attchmtsFilesField = this.fg.get(ResendInvoiceDialogFormFields.ATTACHMENT_FILES);

        if (attchmtsFilesField) {
          this.resetOptions(attchmtsFilesField);
        }
      }
    });

    this.fg.get(ResendInvoiceDialogFormFields.ATTACHMENT_AS).valueChanges.subscribe(res => {
      if (res === AttachmentAsOptions.File && this.isPdfSelected) {
        this.isFileSelected = true;
        this.areAllSelected = this.allSelected ? this.allSelected.checked : false;
        const selectedFilesField = this.fg.get(ResendInvoiceDialogFormFields.ATTACHMENT_FILES);
        let selectedFiles;
        if (selectedFilesField) {
          selectedFiles = selectedFilesField.value || {};
          this.checkSelectedFilesSizes(selectedFiles);
        }
        this.setFileOptions(
          this.invoices.length > 1
          ? {...this.fileOptionsValues, [FileOptions.OneProPerPDF]: true }
          : this.singleFileOptionsValues
        );
      } else {
        this.isFileSelected = false;
        this.showTotalSize = true;
        this.fileSizeExceeded = false;
        this.maxInvoicesExceeded = false;
        this.setFileOptions(this.invoices.length > 1 ? this.fileOptionsValues : this.singleFileOptionsValues);
      }
    });

    this.fg.get(ResendInvoiceDialogFormFields.EMAIL_SUBJECT).valueChanges.subscribe(currentValue => {
      const isDirty = this.fg.get(ResendInvoiceDialogFormFields.EMAIL_SUBJECT).dirty;
      if (isDirty) {
        this.emailSubjectHasChanged = true;
      }
    });
  }

  resetOptions(formGroup) {
    if (formGroup.controls) {
      Object.keys(formGroup.controls).forEach((key) => {
        formGroup.get(key).setValue(false);
      });
    }
  }

  setAverageOfInvoices(files) {
    Object.entries(files).forEach((element: any) => {
      if (element[0] && element[1]) {
        const pro = element[0];
        const fileSize = element[1];
        this.availableInvoices[pro] = fileSize;
      }
    });
  }

  /*
    Temporarily unused
  */
  setInvoices(files) {
    Object.entries(files).forEach((element: any) => {
      if (element[0] && element[1]) {
        const pro = element[0];
        const file = element[1].byteLength;
        const marker = 1024; // Change to 1000 if required
        const megaBytes = marker * marker; // One MB is 1024 KB
        const fileSize = (file / megaBytes).toFixed(2);
        this.availableInvoices[pro] = fileSize;
      }
    });
  }

  setAverageOfAvailableFiles(files) {
    let currentSelection = [];
    this.isLoadingFiles = false;
    this.existingTypes = [];

    Object.entries(files).forEach(element => {
      const pro = element[0];
      const values: string[] = element[1] as [];
      if (values) {
        this.availableFiles[pro] = values.map(val => {
          return {
            file: val,
            fileSize: this.getAverageFromConfig(val)
          };
        });
        this.existingTypes = [...this.existingTypes, Object.values(values).map(item => ({ type: item }))];
      }
    });

    const existingTypesForCheckbox = {};
    this.existingTypes = this.existingTypes.reduce((acc, val) => acc.concat(val), []);
    this.existingTypes = [... new Set(this.existingTypes.map(tag => tag.type))]
      .map(item => ({ type: item }));
    this.existingTypes.forEach(item => {
      existingTypesForCheckbox[item.type] = true;
    });

    if (this.existingTypes.length) {
      this.showRightPanel = true;
    }
    const field = this.fg.get(ResendInvoiceDialogFormFields.ATTACHMENT_FILES);
    if (field) {
      currentSelection = Object.entries(field.value).filter(file => Boolean(file[1])).map(item => item[0]);
      this.fg.removeControl(ResendInvoiceDialogFormFields.ATTACHMENT_FILES);
    }

    this.fg.addControl(
      ResendInvoiceDialogFormFields.ATTACHMENT_FILES,
      this.buildFormGroup(existingTypesForCheckbox, currentSelection));
    this.fg.get(ResendInvoiceDialogFormFields.ATTACHMENT_FILES).valueChanges.subscribe(res => {
      this.checkSelectedFilesSizes(res);
    });
    this.cdr.detectChanges();
  }

  /*
    Temporarily unused
  */
  setAvailableFiles(files) {
    let currentSelection = [];
    this.isLoadingFiles = false;
    this.existingTypes = [];

    Object.entries(files).forEach(element => {
      const pro = element[0];
      const values = element[1];
      const array = [];
      if (values) {
        Object.entries(values).forEach(item => {
          const fileType = item[0];
          array[fileType] = {};
        });
        this.availableFiles[pro] = array;
        this.existingTypes = [...this.existingTypes, Object.keys(values).map(item => ({ type: item }))];
      }
    });

    const existingTypesForCheckbox = {};
    this.existingTypes = this.existingTypes.reduce((acc, val) => acc.concat(val), []);
    this.existingTypes = [... new Set(this.existingTypes.map(tag => tag.type))]
      .map(item => ({ type: item }));
    this.existingTypes.forEach(item => {
      existingTypesForCheckbox[item.type] = true;
    });

    if (this.existingTypes.length) {
      this.showRightPanel = true;
    }
    const field = this.fg.get(ResendInvoiceDialogFormFields.ATTACHMENT_FILES);
    if (field) {
      currentSelection = Object.entries(field.value).filter(file => Boolean(file[1])).map(item => item[0]);
      this.fg.removeControl(ResendInvoiceDialogFormFields.ATTACHMENT_FILES);
    }

    this.fg.addControl(
      ResendInvoiceDialogFormFields.ATTACHMENT_FILES,
      this.buildFormGroup(existingTypesForCheckbox, currentSelection));
    this.fg.get(ResendInvoiceDialogFormFields.ATTACHMENT_FILES).valueChanges.subscribe(res => {
      this.checkSelectedFilesSizes(res);
    });
    this.cdr.detectChanges();
  }

  onFileOptionsChange(item, event) {
    if (item === FileOptions.OneInvoicePerEmail) {
      if (event.checked) {
        this.showTotalSize = false;
        this.showErrors = false;
        const oneProPerPdfField = this.optionsFormGroup.get(FileOptions.OneProPerPDF);
        if (oneProPerPdfField) {
          oneProPerPdfField.disable();
        }
      } else {
        this.showErrors = true;
        this.showTotalSize = !this.fileSizeExceeded || !this.maxInvoicesExceeded ? true : false;
        const oneProPerPdfField = this.optionsFormGroup.get(FileOptions.OneProPerPDF);
        if (oneProPerPdfField) {
          oneProPerPdfField.enable();
        }
      }
    }

    if (item === FileOptions.OneProPerPDF) {
      if (event.checked) {
        this.optionsFormGroup.get(FileOptions.OneInvoicePerEmail).disable();
      } else {
        this.optionsFormGroup.get(FileOptions.OneInvoicePerEmail).enable();
      }
    }
  }

  setFileOptions(options) {
    const field = this.fg.get(ResendInvoiceDialogFormFields.FILE_OPTIONS);
    if (field) {
      if (this.invoices.length > 1) {
        this.fileOptions = Object.entries(options).filter(option => Boolean(option[1]));
      } else {
        this.singleFileOptions = Object.entries(options).filter(option => Boolean(option[1]));
      }
      this.fg.removeControl(ResendInvoiceDialogFormFields.FILE_OPTIONS);
    }

    const selectedIds = this.invoices.length > 1 ? this.fileOptions : this.singleFileOptions;

    this.fg.addControl(
      ResendInvoiceDialogFormFields.FILE_OPTIONS,
      this.buildFormGroup(options, selectedIds));
    this.fg.get(ResendInvoiceDialogFormFields.FILE_OPTIONS).valueChanges.subscribe(res => {
      const attachmentAsField = this.fg.get(ResendInvoiceDialogFormFields.ATTACHMENT_AS);
      this.maxInvoicesExceeded = false;

      if (res[FileOptions.OneInvoicePerEmail] &&
        this.isPdfSelected &&
        this.invoices.length > this.maxResendInvoicesPerEmail &&
        (attachmentAsField.value !== AttachmentAsOptions.Link)) {
        this.maxInvoicesExceeded = true;
        this.showTotalSize = false;
        this.errorMessage = `You have selected more than ${this.maxResendInvoicesPerEmail} invoices to resend. Please select fewer invoices or link option`;
      }
    });
  }

  buildFormGroup(options: any, selectedIds: string[] = []): FormGroup {
    const group = this.formBuilder.group({}, {
      validators: []
    });

    Object.entries(options).forEach(option => {
      if (option[1]) {
        const isSelected = selectedIds.some(id => id === option[0]);
        group.addControl(option[0], this.formBuilder.control(isSelected));
      }
    });
    return group;
  }

  checkSelectedFilesSizes(res) {
    const attachmentAsField = this.fg.get(ResendInvoiceDialogFormFields.ATTACHMENT_AS);
    if (attachmentAsField && attachmentAsField.value === AttachmentAsOptions.File) {
      this.fileSizeExceeded = false;
      const selectedFiles = Object.entries(res)
      .filter(file => Boolean(file[1]))
      .map(file => file[0]);

      this.totalSize = 0;

      Object.entries(this.availableInvoices).forEach(pro => {
        this.totalSize += +pro[1] || 0;
      });
      selectedFiles.forEach(file => {
        Object.entries(this.availableFiles).forEach(pro => {
          Object.entries(pro[1]).forEach((type: any) => {
            if (file === type[1].file) {
              this.totalSize += type[1].fileSize || 0;
            }
          });
        });
      });
      this.totalSize = +this.totalSize.toFixed(2);

      if (this.totalSize > this.maxSize) {
        this.fileSizeExceeded = true;
        this.showTotalSize = false;
        this.errorMessage = 'Attachment size exceeds 20 MB. Please select fewer invoices or documents or the link option';
      } else {
        this.showTotalSize = true;
      }
    }
  }

  ngOnDestroy() {
    this.unsubscriber.next();
    this.unsubscriber.complete();
  }

  closeClicked() {
    this.dialogRef.close();
  }

  resendClicked() {
    if (this.fg.valid) {
      const resendPreferences = new ResendPreferences();
      resendPreferences.invoiceModeCd = this.invoiceModeCd;
      resendPreferences.attachmentFormat = this.getAttachmentFormat(
        this.fg.get(ResendInvoiceDialogFormFields.ATTACHMENT_FORMAT).value
      );
      const filesField = this.fg.get(ResendInvoiceDialogFormFields.ATTACHMENT_FILES);
      if (filesField) {
        let files = [];
        files = filesField.value;
        resendPreferences.attachmentList = Object.entries(files)
          .filter(file => Boolean(file[1]))
          .map(item => DmsDocTypeCd[item[0]]);
      }
      switch (this.invoiceModeCd) {
        case InvoiceModeCd.EDI:
          break;
        case InvoiceModeCd.EDI_TRANSFORMATION:
          break;
        case InvoiceModeCd.EMAIL:
          resendPreferences.emailAddressList = this.conditioningService.conditionEmailGroup(
            this.fg.get(ResendInvoiceDialogFormFields.EMAIL_ADDRESS).value
          );
          resendPreferences.emailSubject = this.fg.get(ResendInvoiceDialogFormFields.EMAIL_SUBJECT).value;
          resendPreferences.attachAs = this.fg.get(ResendInvoiceDialogFormFields.ATTACHMENT_AS).value;
          resendPreferences.fileOptions = this.fg.get(ResendInvoiceDialogFormFields.FILE_OPTIONS).value;
          break;
        case InvoiceModeCd.FAX:
          resendPreferences.faxNumber = this.fg.get(ResendInvoiceDialogFormFields.FAX_NUMBER).value;
          break;
        case InvoiceModeCd.FTP:
          break;
        case InvoiceModeCd.PAPER:
          resendPreferences.mailCompanyName = this.fg.get(ResendInvoiceDialogFormFields.COMPANY_NAME).value;
          resendPreferences.mailAddress = this.fg.get(ResendInvoiceDialogFormFields.ADDRESS).value;
          resendPreferences.mailCity = this.fg.get(ResendInvoiceDialogFormFields.CITY).value;
          resendPreferences.mailState = this.fg.get(ResendInvoiceDialogFormFields.STATE).value;
          resendPreferences.mailZip = this.fg.get(ResendInvoiceDialogFormFields.ZIP_CODE).value;
          resendPreferences.mailCountry = this.fg.get(ResendInvoiceDialogFormFields.COUNTRY).value;
          break;
        case InvoiceModeCd.SELF_PAID:
          break;
      }
      this.dialogRef.close(resendPreferences);
    }
  }

  getAttachmentFormat(format: string): InvoicePresentationFormatCd {
    switch (format) {
      case AttachmentLabels.CSV:
        return InvoicePresentationFormatCd.CSV;
      case AttachmentLabels.CSV_WITH_ACCESSORIAL:
        return InvoicePresentationFormatCd.CSV_ACC;
      case AttachmentLabels.INDIVIDUAL:
        return InvoicePresentationFormatCd.INDIVIDUAL;
      case AttachmentLabels.PAPER:
        return InvoicePresentationFormatCd.INDIVIDUAL;
      case AttachmentLabels.PDF:
        return InvoicePresentationFormatCd.PDF;
      case AttachmentLabels.PDFI:
        return InvoicePresentationFormatCd.PDF_IMAGE;
      case AttachmentLabels.XML:
        return InvoicePresentationFormatCd.XML;
    }
  }

  getAverageFromConfig(docType) {
    switch (docType) {
      case DocType.InvoicePdf:
        return this.configManagerService.getSetting(ConfigManagerProperties.arinAverage);
      case DocType.BillOfLading:
        return this.configManagerService.getSetting(ConfigManagerProperties.blAverage);
      case DocType.DeliveryReceipt:
        return this.configManagerService.getSetting(ConfigManagerProperties.drAverage);
      case DocType.LetterOfAuthorization:
        return this.configManagerService.getSetting(ConfigManagerProperties.loaAverage);
      case DocType.WeightInspectionCertificate:
        return this.configManagerService.getSetting(ConfigManagerProperties.wiAverage);
      case DocType.NmfcClassInspectionCertificate:
        return this.configManagerService.getSetting(ConfigManagerProperties.ncicAverage);
      case DocType.Customs:
        return this.configManagerService.getSetting(ConfigManagerProperties.custAverage);
    }
  }

  /*
    Temporarily unused
  */
  setAttachmentsSize$(allSelected = null): Observable<any> {
    return new Observable((observer) => {
      const obs$ = {};
      if (!this.existingTypes.length) {
        observer.next({ success: true, allSelected });
      }

      for (const proNbr in this.availableFiles) {
        if (this.availableFiles.hasOwnProperty(proNbr)) {
          const files = Object.keys(this.availableFiles[proNbr]);
          files.forEach(element => {
            obs$[`${proNbr}-${element}`] = this.dmsDocumentService.getDmsDocument({ referenceNumber: proNbr, docType: element as DocType });
          });
        }
      }

      forkJoin(obs$).subscribe((res: any)  => {
        Object.entries(res).forEach(item => {
          const proNbr = item[0].split('-')[0];
          const fileType = item[0].split('-')[1];
          this.availableFiles[proNbr][fileType].fileSize = item[1]['fileSize'];
          this.availableFiles[proNbr][fileType].file = item[1]['file'];
        });
        observer.next({ success: true, allSelected });
      }, error => {
        observer.error(error);
      });
    });
  }

  private lookupCustByMadCode(madCd: string): Observable<AcctBasicDetails> {
    const request = new GetAcctBasicDetailsRqst();
    const isValidMADCode = this.formatValidationService.isMadCodeValid(madCd);

    if (!isValidMADCode) {
      return of(null);
    }

    request.acctInstId = madCd;
    return this.customerService.getAcctBasicDetails(request).pipe(
      take(1),
      map((response) => {
        return response && response.acctBasicDetails ? response.acctBasicDetails : undefined;
      })
    );
  }

}

<div class="remove-invoices-dialog">
  <h2 mat-dialog-title>Remove Invoices?</h2>
  <mat-dialog-content class="remove-invoices-dialog-content">
    Do you really want to remove {{ invoicesToRemove }}?
  </mat-dialog-content>
  <mat-dialog-actions class="remove-invoices-dialog-actionbar">
    <button mat-flat-button color="primary" [mat-dialog-close]="true" tabindex="-1">REMOVE</button>
    <button mat-stroked-button color="primary" [mat-dialog-close]="false" tabindex="-1">CANCEL</button>
  </mat-dialog-actions>
</div>

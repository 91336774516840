import { Component, Inject, OnInit } from '@angular/core';
import { MAT_DIALOG_DATA, MatDialogRef } from '@angular/material/dialog';

import { RemoveInvoicesDialogDataInterface } from '@shared/models';

@Component({
  selector: 'app-remove-invoices-dialog',
  templateUrl: './remove-invoices-dialog.component.html',
  styleUrls: ['./remove-invoices-dialog.component.scss'],
})
export class RemoveInvoicesDialogComponent implements OnInit {
  invoicesToRemove: string;

  constructor(
    private dialogRef: MatDialogRef<RemoveInvoicesDialogComponent>,
    @Inject(MAT_DIALOG_DATA) public data: RemoveInvoicesDialogDataInterface
  ) {
    this.invoicesToRemove = data.invoicesToRemove > 1 ? `these ${data.invoicesToRemove} invoices` : 'one invoice';
  }

  ngOnInit() {}
}

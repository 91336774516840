import { Pipe, PipeTransform } from '@angular/core';
import { DocType, DmsDocType } from '@shared/enums';

@Pipe({
  name: 'documentType',
})
export class DocumentTypePipe implements PipeTransform {
  transform(value: DocType, args?: any): any {
    let formattedValue = '';
    switch (value) {
      case DocType.DeliveryReceipt:
        formattedValue = DmsDocType.DeliveryReceipt;
        break;
      case DocType.BillOfLading:
        formattedValue = DmsDocType.BillOfLading;
        break;
      case DocType.InvoicePdf:
        formattedValue = DmsDocType.InvoicePdf;
        break;
      case DocType.NmfcClassInspectionCertificate:
        formattedValue = DmsDocType.NmfcClassInspectionCertificate;
        break;
      case DocType.LetterOfAuthorization:
        formattedValue = DmsDocType.LetterOfAuthorization;
        break;
      case DocType.WeightInspectionCertificate:
        formattedValue = DmsDocType.WeightInspectionCertificate;
        break;
      case DocType.Customs:
        formattedValue = DmsDocType.Customs;
    }
    return formattedValue;
  }
}

import { Injectable } from '@angular/core';
import { AbstractControl, AsyncValidatorFn, ValidatorFn } from '@angular/forms';

import {
  XrtAttributeFilter,
  XrtSearchQueryHeader,
  XrtCustomerSearchRqst,
  XrtCustomerSearchResp,
  XrtCustomerSearchFilter,
  ElasticSearchApiService,
} from '@xpo-ltl/sdk-elasticsearch';
  import { FormatValidationService } from '@xpo-ltl/common-services';

import { Observable, of } from 'rxjs';
import { map, take } from 'rxjs/operators';

import { AddRfcDialogData } from '@shared/models/add-rfc-dialog-data';

export function madCodeValidator(
  formatValidator: FormatValidationService,
  data: AddRfcDialogData = null,
  initialMadcode: string = ''): ValidatorFn {
  return (control: AbstractControl): { [key: string]: any } => {
    if (control && control.value) {
      if (!formatValidator.isMadCodeValid(control.value)) {
        return { madCode: control.value };
      } else if (control.value === initialMadcode) {
        return null;
      } else if (data && data.existingMadCode.length && data.existingMadCode.includes(control.value)) {
        return { madCodeIsAlreadyPresent: true };
      }
    }
    return null;
  };
}

export function isEmptyInputValue(value: any): boolean {
  // we don't check for string here so it also works with arrays
  return value === null || value.length === 0;
}

@Injectable()
export class RemoteMadcodeValidator {

  constructor(private elasticsearchService: ElasticSearchApiService) {}

  existingMadcodeValidator(initialMadcode: string = ''): AsyncValidatorFn {

    return (control: AbstractControl): Observable<{ [key: string]: any } | null> => {
      if (isEmptyInputValue(control.value)) {
        return of(null);
      } else if (control.value === initialMadcode) {
        return of(null);
      } else {
        return this.xrtCustomerSearch(control.value)
          .pipe(
            take(1),
            map((response: XrtCustomerSearchResp)  => {
              if (response.result.length) {
                const customer = response.result[0];
                return customer.countryCd !== 'MX' ? { invalidAsyncMXMadCode: true } : null;
              } else {
                return { invalidAsyncMadCode: true };
              }
            })
          );
      }
    };
  }

  private xrtCustomerSearch(madcode: string): Observable<XrtCustomerSearchResp> {
    const options = new XrtCustomerSearchRqst();
    options.header = new XrtSearchQueryHeader();
    options.filter = new XrtCustomerSearchFilter();
    options.header.page = 1;
    options.header.pageSize = 1;
    options.header.sortExpressions = [{ IsDescendingSort: true, Column: 'seqNbr', direction: 'asc' }];
    options.filter.acctMadCd = new XrtAttributeFilter();
    options.filter.acctMadCd.equals = madcode;
    return this.elasticsearchService.xrtCustomerSearch(options);
  }
}

import { DatePipe } from '@angular/common';
import { Injectable, OnDestroy } from '@angular/core';

import { take, catchError } from 'rxjs/operators';
import { BehaviorSubject, throwError } from 'rxjs';

import { Invoice } from '@xpo-ltl/sdk-invoice';
import { BillTypeCd } from '@xpo-ltl/sdk-common';
import {
  AccountsReceivableApiService,
  ListArPaymentDetailsByProResp,
  ListArPaymentDetailsByProQuery,
} from '@xpo-ltl/sdk-accountsreceivable';

import { DataOptions } from '@xpo-ltl/data-api';
import { ConditioningService } from '@xpo-ltl/common-services';

import { PaymentHistoryData } from '@shared/models/payment-history-data';
import { ChargeToCdPipe } from '@shared/pipes/charge-to-cd/charge-to-cd.pipe';

import { InvoiceAuditorService } from '../invoice-auditor/invoice-auditor.service';

@Injectable({
  providedIn: 'root',
})
export class PaymentHistoryService {

  hasPayments = new BehaviorSubject<boolean>(false);
  paymentHistory = new BehaviorSubject<any>('nothing');
  private paymentHistoryData: PaymentHistoryData = new PaymentHistoryData();

  constructor(
    private datePipe: DatePipe,
    private chargeToCodeTypePipe: ChargeToCdPipe,
    public invoiceService: InvoiceAuditorService,
    private conditioningService: ConditioningService,
    private accountsReceivableApiService: AccountsReceivableApiService,
  ) {}

  getPaymentHistory(userValue: any) {
    userValue = this.conditioningService.conditionProNumber(userValue, 11);

    return this.invoiceService.invoice$.pipe(take(1)).subscribe((response: Invoice) => {
      this.paymentHistoryData.pickupDate = response.invoiceDetail.shipmentInfo.pickupDate;
      this.paymentHistoryData.custMadCd = response.invoiceClobData.billTo
        ? response.invoiceClobData.billTo.custMadCd
        : '';
        const billTypeCd = response.invoiceHeader.billTypeCd !== BillTypeCd.THIRD_PARTY ?
        response.invoiceHeader.billTypeCd :
        this.chargeToCodeTypePipe.transform(response.invoiceHeader.chargeToCd);
      this.getPaymentHistory2(userValue, billTypeCd);
    });
  }

  getPaymentHistory2(userValue: any, billTypeCd: string) {
    const query = new ListArPaymentDetailsByProQuery();

    const dataOptions: DataOptions = {};
    dataOptions.toastOnError = false;
    dataOptions.loadingOverlayEnabled = false;
    query.proNbr = userValue;
    query.pickupDate = this.paymentHistoryData.pickupDate;

    this.accountsReceivableApiService
      .listArPaymentDetailsByPro(query, dataOptions)
      .pipe(
        take(1),
        catchError(err => throwError(false)),
      )
      .subscribe(
        (response: ListArPaymentDetailsByProResp ) => {
          for (const row of response.arPaymentDetails) {
            row['dateString'] = this.datePipe.transform(row.paymentDate, 'shortDate');
          }
          const filteredPayments = response.arPaymentDetails.filter(payment => {
            return payment.chargeCd.includes(billTypeCd);
          });
          response.arPaymentDetails = filteredPayments;
          this.paymentHistoryData.arrPayDet = response;
          this.hasPayments.next(this.paymentHistoryData.arrPayDet.arPaymentDetails.length > 0);
          this.paymentHistory.next(this.paymentHistoryData);
        },
        (err) => {
          this.hasPayments.next(false);
        }
      );
  }
}

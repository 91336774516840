import { Invoice, InvoiceSummary } from '@xpo-ltl/sdk-invoice';

export interface BothBillDataInterface {
  proNumber: string;
  invoices?: Invoice[];
  invoiceSummaries?: InvoiceSummary[];
}

export class BothBillData implements BothBillDataInterface {
  constructor(public proNumber: string, public invoices?: Invoice[], public invoiceSummaries?: InvoiceSummary[]) {}
}

import { Pipe, PipeTransform } from '@angular/core';
@Pipe({
  name: 'millisToDays',
})
export class MillisToDaysPipe implements PipeTransform {
  static millisInDay = 1000 * 24 * 60 * 60;

  transform(value: any, args?: any): any {
    let displayValue = ' ';
    if (value && !isNaN(value)) {
      displayValue = String(Math.floor(Number(value) / MillisToDaysPipe.millisInDay));
    }
    return displayValue;
  }
}

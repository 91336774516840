export enum ReleaseRoles {
  TstConwayDefaultTabigator = 'CONWAY/DEFAULT_TABIGATOR',
  TstConwayAdDefaultTabigator = 'CONWAY-AD/DEFAULT_TABIGATOR',
  TstConwayInvoiceSpecialist = 'CONWAY/TST_LTL_INVOICE_SPECIALIST',
  TstConwayAdInvoiceSpecialist = 'CONWAY-AD/TST_LTL_INVOICE_SPECIALIST',
  TstConwayInvoiceExl = 'CONWAY/TST_LTL_INVOICE_EXL',
  TstConwayAdInvoiceExl = 'CONWAY-AD/TST_LTL_INVOICE_EXL',
  TstCtsAdInvoiceSpecialist = 'CTS-AD/TST_LTL_INVOICE_SPECIALIST',
  TstConwayInvoiceRfc = 'CONWAY/TST_LTL_INVOICE_RFC',
  TstConwayAdInvoiceRfc = 'CONWAY-AD/TST_LTL_INVOICE_RFC',
  TstConwayInvoiceMexico = 'CONWAY/TST_LTL_INVOICE_MEXICO',
  TstConwayAdInvoiceMexico = 'CONWAY-AD/TST_LTL_INVOICE_MEXICO',
  TstCtsAdInvoiceExl = 'CTS-AD/TST_LTL_INVOICE_EXL',
  TstCtsAdInvoiceRfc = 'CTS-AD/TST_LTL_INVOICE_RFC',
  TstCtsAdInvoiceMexico = 'CTS-AD/TST_LTL_INVOICE_MEXICO',
  TstCfiAdInvoiceSpecialist = 'CFI-AD/TST_LTL_INVOICE_SPECIALIST',
  TstCfiAdInvoiceExl = 'CFI-AD/TST_LTL_INVOICE_EXL',
  TstCfiAdInvoiceRfc = 'CFI-AD/TST_LTL_INVOICE_RFC',
  TstCfiAdInvoiceMexico = 'CFI-AD/TST_LTL_INVOICE_MEXICO',
  TstAppInvapiReleaseHelInvoice = 'Application/INVAPI_ReleaseHeldInvoice',

  ProdConwayInvoiceSpecialist = 'CONWAY/LTL_INVOICE_SPECIALIST',
  ProdConwayAdInvoiceSpecialist = 'CONWAY-AD/LTL_INVOICE_SPECIALIST',
  ProdConwayInvoiceExl = 'CONWAY/LTL_INVOICE_EXL',
  ProdConwayInvoiceRfc = 'CONWAY/LTL_INVOICE_RFC',
  ProdConwayInvoiceMexico = 'CONWAY/LTL_INVOICE_MEXICO',
  ProdConwayAdInvoiceExl = 'CONWAY-AD/LTL_INVOICE_EXL',
  ProdConwayAdInvoiceRfc = 'CONWAY-AD/LTL_INVOICE_RFC',
  ProdConwayAdInvoiceMexico = 'CONWAY-AD/LTL_INVOICE_MEXICO',
  ProdCtsAdInvoiceSpecialist = 'CTS-AD/LTL_INVOICE_SPECIALIST',
  ProdCtsAdInvoiceExl = 'CTS-AD/LTL_INVOICE_EXL',
  ProdCtsAdInvoiceRfc = 'CTS-AD/LTL_INVOICE_RFC',
  ProdCtsAdInvoiceMexico = 'CTS-AD/LTL_INVOICE_MEXICO',
  ProdCfiAdInvoiceSpecialist = 'CFI-AD/LTL_INVOICE_SPECIALIST',
  ProdCfiAdInvoiceExl = 'CFI-AD/LTL_INVOICE_EXL',
  ProdCfiAdInvoiceRfc = 'CFI-AD/LTL_INVOICE_RFC',
  ProdCfiAdInvoiceMexico = 'CFI-AD/LTL_INVOICE_MEXICO',
  ProdAppInvapiReleaseHelInvoice = 'Application/INVAPI_ReleaseHeldInvoice',
}

export enum InvoiceRoles {
  TstConwayDefaultTabigator = 'CONWAY/DEFAULT_TABIGATOR',
  TstConwayAdDefaultTabigator = 'CONWAY-AD/DEFAULT_TABIGATOR',
  TstCtsAdDefaultTabigator = 'CTS-AD/DEFAULT_TABIGATOR',
  TstCfiAdDefaultTabigator = 'CFI-AD/DEFAULT_TABIGATOR',
  TstConwayInvoiceSpecialist = 'CONWAY/TST_LTL_INVOICE_SPECIALIST',
  TstConwayAdInvoiceSpecialist = 'CONWAY-AD/TST_LTL_INVOICE_SPECIALIST',
  TstCtsAdInvoiceSpecialist = 'CTS-AD/TST_LTL_INVOICE_SPECIALIST',
  TstCfiAdInvoiceSpecialist = 'CFI-AD/TST_LTL_INVOICE_SPECIALIST',
  TstConwayInvoiceAnalyst = 'CONWAY/TST_LTL_INVOICE_ANALYST',
  TstConwayAdInvoiceAnalyst = 'CONWAY-AD/TST_LTL_INVOICE_ANALYST',
  TstCtsAdInvoiceAnalyst = 'CTS-AD/TST_LTL_INVOICE_ANALYST',
  TstCfiAdInvoiceAnalyst = 'CFI-AD/TST_LTL_INVOICE_ANALYST',
  TstConwayInvoiceExl = 'CONWAY/TST_LTL_INVOICE_EXL',
  TstConwayAdInvoiceExl = 'CONWAY-AD/TST_LTL_INVOICE_EXL',
  TstCtsAdInvoiceExl = 'CTS-AD/TST_LTL_INVOICE_EXL',
  TstCfiAdInvoiceExl = 'CFI-AD/TST_LTL_INVOICE_EXL',
  TstConwayInvoiceMexico = 'CONWAY/TST_LTL_INVOICE_MEXICO',
  TstConwayAdInvoiceMexico = 'CONWAY-AD/TST_LTL_INVOICE_MEXICO',
  TstCfiAdInvoiceMexico = 'CFI-AD/TST_LTL_INVOICE_MEXICO',
  TstCtsAdInvoiceMexico = 'CTS-AD/TST_LTL_INVOICE_MEXICO',
  TstInvoicePresentmentTibcoApp = 'Application/ltltoolsappuser_InvoicePresentmentTibcoApp_SANDBOX',
  TstQAAppUse = 'Application/QAAppUse',

  ProdConwayDefaultTabigator = 'CONWAY/DEFAULT_TABIGATOR',
  ProdConwayAdDefaultTabigator = 'CONWAY-AD/DEFAULT_TABIGATOR',
  ProdCtsAdDefaultTabigator = 'CTS-AD/DEFAULT_TABIGATOR',
  ProdCfiAdDefaultTabigator = 'CFI-AD/DEFAULT_TABIGATOR',
  ProdConwayInvoiceSpecialist = 'CONWAY/LTL_INVOICE_SPECIALIST',
  ProdConwayAdInvoiceSpecialist = 'CONWAY-AD/LTL_INVOICE_SPECIALIST',
  ProdCtsAdInvoiceSpecialist = 'CTS-AD/LTL_INVOICE_SPECIALIST',
  ProdCfiAdInvoiceSpecialist = 'CFI-AD/LTL_INVOICE_SPECIALIST',
  ProdConwayInvoiceAnalyst = 'CONWAY/LTL_INVOICE_ANALYST',
  ProdConwayAdInvoiceAnalyst = 'CONWAY-AD/LTL_INVOICE_ANALYST',
  ProdCtsAdInvoiceAnalyst = 'CTS-AD/LTL_INVOICE_ANALYST',
  ProdCfiAdInvoiceAnalyst = 'CFI-AD/LTL_INVOICE_ANALYST',
  ProdConwayInvoiceExl = 'CONWAY/LTL_INVOICE_EXL',
  ProdConwayAdInvoiceExl = 'CONWAY-AD/LTL_INVOICE_EXL',
  ProdCtsAdInvoiceExl = 'CTS-AD/LTL_INVOICE_EXL',
  ProdCfiAdInvoiceExl = 'CFI-AD/LTL_INVOICE_EXL',
  ProdConwayInvoiceMexico = 'CONWAY/LTL_INVOICE_MEXICO',
  ProdConwayAdInvoiceMexico = 'CONWAY-AD/LTL_INVOICE_MEXICO',
  ProdCfiAdInvoiceMexico = 'CFI-AD/LTL_INVOICE_MEXICO',
  ProdCtsAdInvoiceMexico = 'CTS-AD/LTL_INVOICE_MEXICO',
  ProdInvoicePresentmentTibcoApp = 'Application/ltltoolsappuser_InvoicePresentmentTibcoApp_SANDBOX',
}

export enum RfcReferenceRoles {
  ProdSpeedyInvoicingApp = 'Application/LTLToolsAppUser_SpeedyG-InvoicingApp_PRODUCTION',
  ProdAppBillOfLadingWebApp = 'Application/LTLToolsAppUser_BillOfLadingWebApp_PRODUCTION',
}

export enum ResendRoles {
  ProdAppInvapiResendInvoice = 'Application/INVAPI_ResendInvoice',
}

export enum ForceEDIPaper {
  ProdInvoiceCreationTibcoApp = 'Application/ltltoolsappuser_InvoiceCreationTibcoApp_PRODUCTION',
  ProdInvoicePresentmentTibcoApp = 'Application/ltltoolsappuser_InvoicePresentmentTibcoApp_PRODUCTION',
  ProdnvapiForceEDIPaper = 'Application/INVAPI_ForceEDIPaper',
  TestInvoiceCreationTibcoApp = 'Application/ltltoolsappuser_InvoiceCreationTibcoApp_SANDBOX',
}

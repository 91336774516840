import { Injectable } from '@angular/core';
import { BehaviorSubject } from 'rxjs';

@Injectable({ providedIn: 'root' })
export class AppConstantsService {
  readonly defaultDateFormat = 'MM/dd/yyyy';
  invoiceReadMode: BehaviorSubject<boolean> = new BehaviorSubject<boolean>(false);

  // Role Guard
  ROLE_CONTEXT = 'Role';
}

import { Component, Inject, OnInit } from '@angular/core';
import { MAT_DIALOG_DATA } from '@angular/material/dialog';
import { RemoveInvoicesDialogDataInterface } from '@shared/models';

@Component({
  selector: 'app-remove-rfc-dialog',
  templateUrl: './remove-rfc-dialog.component.html',
  styleUrls: ['./remove-rfc-dialog.component.scss'],
})
export class RemoveRfcDialogComponent implements OnInit {

  invoicesToRemove: string;

  constructor(
    @Inject(MAT_DIALOG_DATA) public data: RemoveInvoicesDialogDataInterface
  ) {
    this.invoicesToRemove = data.invoicesToRemove > 1 ? `the ${data.invoicesToRemove} selected RFCs` : 'the selected RFC';
  }

  ngOnInit() {}
}

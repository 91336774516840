export interface EditRfcDialogDataInterface {
  madCode: string;
  name: string;
  rfcNbr: string;
  address: string;
  pros: any[];
  customerNbr: number;
  isInvoice: boolean;
  existingMadCodes: string[];
}

export class EditRfcDialogData implements EditRfcDialogDataInterface {
  constructor(
    public madCode: string,
    public name: string,
    public rfcNbr: string,
    public address: string,
    public pros: any[],
    public customerNbr: number,
    public isInvoice: boolean,
    public existingMadCodes: string[]
  ) {}
}

<div class="remove-rfc-dialog">
  <h2 mat-dialog-title>Remove RFC?</h2>
  <mat-dialog-content class="remove-rfc-dialog-content">
    Do you want to delete {{ invoicesToRemove }}?
  </mat-dialog-content>
  <mat-dialog-actions class="remove-rfc-dialog-actionbar">
    <button mat-button color="primary" [mat-dialog-close]="false">Cancel</button>
    <button mat-button color="primary" [mat-dialog-close]="true">Remove</button>
  </mat-dialog-actions>
</div>

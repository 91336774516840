<app-region-banner></app-region-banner>

<xpo-shell [drawerClosedByDefault]="true">
  <xpo-header-app-name>Edge</xpo-header-app-name>
  <xpo-header-sub-app-name>Invoicing</xpo-header-sub-app-name>

  <xpo-header-navigation>
    <button [routerLink]="routerUriComponents.INVOICING_MAIN_PAGE" routerLinkActive="xpo-selected" *ngIf="isViewInvoiceVisible">{{ appHeaderLabels.VIEW_INVOICE }}</button>
    <button [routerLink]="routerUriComponents.RELEASE_INVOICE" routerLinkActive="xpo-selected" *ngIf="isReleaseInvoicesVisible && !hideReleaseInvoicesTab">{{ appHeaderLabels.RELEASE_INVOICES }}</button>
    <button [routerLink]="routerUriComponents.INVOICING_RESEND_PAGE" routerLinkActive="xpo-selected" *ngIf="isResendInvoicesVisible && !hideResendInvoicesTab">{{ appHeaderLabels.RESEND_INVOICES }}</button>
    <button [routerLink]="routerUriComponents.RFC_MANAGEMENT" routerLinkActive="xpo-selected" *ngIf="isRFCManagementVisible && !hideRFCManagementTab">{{ appHeaderLabels.RFC_MANAGEMENT }}</button>
  </xpo-header-navigation>

  <xpo-header-utilities-actions *ngIf="!!invoiceAuditorService.user && !hideProSearchComponent">
    <div [formGroup]="invoiceSearchForm">
      <mat-form-field class="app-header-content-right-search-field" floatLabel="never">
        <mat-label>Enter a PRO# to view an invoice</mat-label>
        <input
          matInput
          autofocus
          placeholder="Enter a PRO# to view an invoice"
          [formControlName]="appToolbarFormFields.UserQuery" />
        <mat-icon matSuffix>search</mat-icon>
      </mat-form-field>
    </div>
  </xpo-header-utilities-actions>

  <div class="app-container" fxFlex="" fxLayout="column">
    <div
      fxLayout="column"
      fxFlex=""
      class="app-container-content"
      [ngClass]="{ 'app-container-content-non-prod': !isProduction }"
    >
      <div
        class="app-container-inner-content"
        [ngClass]="{ 'remove-margin': isResend }"
      >
        <app-notification></app-notification>
        <router-outlet></router-outlet>
      </div>
    </div>
  </div>

  <app-footer></app-footer>
</xpo-shell>

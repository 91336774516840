<h1 mat-dialog-title class="xpo-ConfirmDialog-header"><mat-icon>warning</mat-icon> {{ data.title }}</h1>
<mat-dialog-content class="xpo-ConfirmDialog-content">
  <div mat-dialog-content>
    <p>{{ data.body }}</p>
  </div>

  <div mat-dialog-actions fxLayoutAlign="start start">
    <button mat-flat-button [mat-dialog-close]="true">OK</button>
  </div>
</mat-dialog-content>

import { Pipe, PipeTransform } from '@angular/core';
import { InvoiceLine } from '@xpo-ltl/sdk-invoice';

@Pipe({
  name: 'invoiceLineDescription',
})
export class InvoiceLineDescriptionPipe implements PipeTransform {
  transform(invoiceLine: Partial<InvoiceLine>, args?: any): any {
    const formattedValue = `${invoiceLine.packaging || ''} ${
      invoiceLine.lineNbr === 'Commodity-DefWeight' ? 'DEF WT: ' : ''
    }  ${invoiceLine.description || ''}`;
    return invoiceLine.hazmatInd === 'Y' ? `HAZMAT ${formattedValue}` : formattedValue;
  }
}

import { NgModule } from '@angular/core';
import { CommonModule } from '@angular/common';
import { FlexLayoutModule } from '@angular/flex-layout';

import { XpoSnackBarModule } from '@xpo-ltl/ngx-ltl-core';

import { MaterialModule } from '@shared/material/material.module';

import { AppToolbarService } from './layout/app-toolbar/services/app-toolbar.service';

import { NotificationComponent } from './notification/notification.component';
import { AppFooterComponent } from './layout/app-footer/app-footer.component';
import { AppToolbarComponent } from './layout/app-toolbar/app-toolbar.component';
import { RegionBannerComponent } from './layout/region-banner/region-banner.component';
import { VersionBannerComponent } from './layout/version-banner/version-banner.component';
import { NotAuthorizedComponent } from './layout/not-authorized/not-authorized.component';

@NgModule({
  declarations: [
    AppFooterComponent,
    AppToolbarComponent,
    NotificationComponent,
    RegionBannerComponent,
    VersionBannerComponent,
    NotAuthorizedComponent,
  ],
  imports: [CommonModule, MaterialModule, FlexLayoutModule, XpoSnackBarModule],
  exports: [
    AppFooterComponent,
    AppToolbarComponent,
    NotificationComponent,
    RegionBannerComponent,
    NotAuthorizedComponent,
  ],
  providers: [AppToolbarService],
})
export class CoreModule {}

<div [formGroup]="fg" container="resend-invoices-dialog" class="resend-invoices-dialog">
  <h2 mat-dialog-title class="resend-invoices-dialog-header">
    Resend Invoice
    <xpo-dialog-close-button></xpo-dialog-close-button>
  </h2>
  <mat-dialog-content>
    <div class="resend-invoices-dialog-content" *ngIf="!isLoadingFiles; else showLoader">

      <div fxLayout="row wrap" fxFill fxLayoutAlign="start start">

        <div fxFlex="100" class="alert" *ngIf="shouldShowError">
          <xpo-status-indicator statusText="ATTENTION" statusColor="#dc0a0a" isLabel="true"></xpo-status-indicator>
          <span class="alert-text">{{ errorMessage }}</span>
        </div>

        <div fxFlex="100" class="alert" *ngIf="shouldShowFileSize">
          <xpo-status-indicator statusText="INFO" statusColor="blue" isLabel="true"></xpo-status-indicator>
          <span class="alert-text">Based on the current document selection, the estimated combined file sizes are {{ totalSize }} MB</span>
        </div>

        <div fxFlex="100" class="alert" *ngIf="isMultiple">
          <xpo-status-indicator statusText="INFO" statusColor="blue" isLabel="true"></xpo-status-indicator>
          <span class="alert-text">Multiple Send To parties are present and will be transmitted as a group
          </span>
        </div>

        <div fxFlex="75" class="resend-invoices-dialog-content-left"
          [ngClass]="{ 'separator' :showRightPanel, 'fix-height': fileSizeExceeded || maxInvoicesExceeded || shouldShowFileSize }"
          [ngStyle]="{ 'height': !fileSizeExceeded && !maxInvoicesExceeded && !shouldShowFileSize ? '99%' : null }">

          <div
            *ngIf="activePanelState === activePanelStates.FAX"
          >
            <div fxLayout="row" fxLayoutAlign="stretch start">
              <mat-form-field fxFill floatLabel="always">
                <input
                  type="text"
                  matInput
                  placeholder="Fax Number"
                  maxlength="20"
                  [formControlName]="resendFormFields.FAX_NUMBER"
                />
                <mat-hint>###-###-####</mat-hint>
                <mat-error>
                  <div class="mat-error" *ngIf="hasError('required', resendFormFields.FAX_NUMBER)">Required</div>
                  <div class="mat-error" *ngIf="hasError('phoneFormat', resendFormFields.FAX_NUMBER)">
                    Invalid Phone Format
                  </div>
                </mat-error>
              </mat-form-field>
            </div>
          </div>

          <div
            *ngIf="activePanelState === activePanelStates.EMAIL_ADDRESS"
          >
            <div fxLayout="row" fxLayoutAlign="stretch start">
              <mat-form-field fxFill floatLabel="always" fxFlex="60">
                <mat-label>Email *</mat-label>
                <input
                  type="text"
                  matInput
                  placeholder="Email addresses separated by comma"
                  [formControlName]="resendFormFields.EMAIL_ADDRESS"
                />
                <mat-error>
                  <div class="mat-error" *ngIf="hasError('required', resendFormFields.EMAIL_ADDRESS)">Required</div>
                  <div class="mat-error" *ngIf="hasError('emailAddressMaxLength', resendFormFields.EMAIL_ADDRESS)">
                    One of the address entered exceeds the maximum length
                  </div>
                  <div class="mat-error" *ngIf="hasError('emailAddressFormat', resendFormFields.EMAIL_ADDRESS)">
                    Invalid Email Address
                  </div>
                </mat-error>
              </mat-form-field>
            </div>

            <div fxLayout="row" fxLayoutAlign="stretch start">
              <mat-form-field fxFill floatLabel="always" fxFlex="60">
                <mat-label>Subject</mat-label>
                <input
                  type="text"
                  matInput
                  placeholder="Subject"
                  [formControlName]="resendFormFields.EMAIL_SUBJECT"
                />
                <mat-error>
                  <div class="mat-error" *ngIf="hasError('required', resendFormFields.EMAIL_SUBJECT)">Required</div>
                  <div class="mat-error" *ngIf="hasError('maxlength', resendFormFields.EMAIL_SUBJECT)">Maximum 78 characters</div>
                  <div class="mat-error" *ngIf="hasError('pattern', resendFormFields.EMAIL_SUBJECT)">Invalid format</div>
                </mat-error>
              </mat-form-field>
            </div>

          </div>

          <div
            *ngIf="activePanelState === activePanelStates.NAME_ADDRESS"
          >
            <div fxLayout="row" fxLayoutAlign="stretch start">
              <mat-form-field fxFill floatLabel="always">
                <input
                  type="text"
                  matInput
                  placeholder="Company Name"
                  maxlength="100"
                  [formControlName]="resendFormFields.COMPANY_NAME"
                />
                <mat-error>
                  <div class="mat-error" *ngIf="hasError('required', resendFormFields.COMPANY_NAME)">Required</div>
                </mat-error>
              </mat-form-field>
            </div>
            <div fxLayout="row" fxLayoutAlign="stretch start">
              <mat-form-field fxFill floatLabel="always">
                <input
                  type="text"
                  matInput
                  placeholder="Address"
                  maxlength="100"
                  [formControlName]="resendFormFields.ADDRESS"
                />
                <mat-error>
                  <div class="mat-error" *ngIf="hasError('required', resendFormFields.ADDRESS)">Required</div>
                </mat-error>
              </mat-form-field>
            </div>
            <div fxLayout="row" fxLayoutAlign="space-between start">
              <mat-form-field floatLabel="always" fxFlex="50">
                <input type="text" matInput placeholder="City" maxlength="100" [formControlName]="resendFormFields.CITY" />
                <mat-error>
                  <div class="mat-error" *ngIf="hasError('required', resendFormFields.CITY)">Required</div>
                </mat-error>
              </mat-form-field>
              <mat-form-field fxFlex="20" floatLabel="always">
                <input type="text" matInput placeholder="State" maxlength="2" [formControlName]="resendFormFields.STATE" />
                <mat-error>
                  <div class="mat-error" *ngIf="hasError('required', resendFormFields.STATE)">Required</div>
                </mat-error>
              </mat-form-field>
              <!-- <div fxLayout="row" fxFlex="20">
                <mat-form-field fxFill floatLabel="always">
                  <mat-select placeholder="State" [formControlName]="resendFormFields.STATE" xpoSelect>
                    <mat-option *ngFor="let state of states" value="{{state}}">
                      {{ state }}
                    </mat-option>
                  </mat-select>
                </mat-form-field>
              </div> -->
              <mat-form-field fxFlex="20" floatLabel="always">
                <input
                  type="text"
                  matInput
                  placeholder="Zip"
                  maxlength="15"
                  [formControlName]="resendFormFields.ZIP_CODE"
                />
                <mat-error>
                  <div class="mat-error" *ngIf="hasError('required', resendFormFields.ZIP_CODE)">Required</div>
                </mat-error>
              </mat-form-field>
            </div>
            <div fxLayout="row" fxLayoutAlign="stretch start">
              <mat-form-field fxFill floatLabel="always">
                <mat-select placeholder="Country" [formControlName]="resendFormFields.COUNTRY" xpoSelect>
                  <mat-option *ngFor="let country of countries" value="{{ country }}">
                    {{ country }}
                  </mat-option>
                </mat-select>
                <mat-error>
                  <div class="mat-error" *ngIf="hasError('required', resendFormFields.COUNTRY)">Required</div>
                </mat-error>
              </mat-form-field>
            </div>
          </div>

          <div fxLayout="row" fxLayoutAlign="space-between baseline" fxLayoutGap="10px">
            <mat-form-field fxFill floatLabel="always" fxFlex="50">
              <mat-label>Attach As</mat-label>
              <mat-select [formControlName]="resendFormFields.ATTACHMENT_FORMAT" xpoSelect>
                <mat-option *ngFor="let attachment of attachments" value="{{ attachment }}">
                  {{ attachment }}
                </mat-option>
              </mat-select>
              <mat-error>
                <div class="mat-error" *ngIf="hasError('required', resendFormFields.ATTACHMENT_FORMAT)">Required</div>
              </mat-error>
            </mat-form-field>
            <mat-radio-group
              fxFlex="50"
              *ngIf="isPdfSelected"
              [formControlName]="resendFormFields.ATTACHMENT_AS">
              <mat-radio-button [value]="attachmentAsOptions.File">{{ attachmentAsOptions.File }}</mat-radio-button>
              <mat-radio-button [value]="attachmentAsOptions.Link">{{ attachmentAsOptions.Link }}</mat-radio-button>
            </mat-radio-group>
          </div>

          <div *ngIf="isPdfSelected && isFileSelected && invoices.length === 1">
            <label>File Options</label>
            <xpo-checkbox-group class="remove-margin">
              <mat-checkbox
                *ngFor="let item of singleFileOptions"
                [formControl]="optionsFormGroup?.controls[item[0]]"
                >
                {{ item[0] }}
              </mat-checkbox>
            </xpo-checkbox-group>
          </div>

          <div
            *ngIf="isPdfSelected && invoices.length > 1">
            <label>File Options</label>
            <xpo-checkbox-group class="remove-margin">
              <mat-checkbox
                *ngFor="let item of fileOptions"
                (change)="onFileOptionsChange(item[0], $event)"
                [formControl]="optionsFormGroup?.controls[item[0]]"
                >
                {{ item[0] }}
              </mat-checkbox>
            </xpo-checkbox-group>
          </div>

        </div>

        <div fxFlex="25" class="resend-invoices-dialog-content-right" *ngIf="showRightPanel">
          <div class="form-group">
            <xpo-checkbox-group>
              <mat-label>DOCUMENTS</mat-label>
                <mat-checkbox
                  #allSelected
                  [checked]="areAllSelected"
                  [indeterminate]="isIndeterminate"
                  (change)="toggleAllSelection()">
                  Select All
                </mat-checkbox>
                <mat-checkbox
                  *ngFor="let item of existingTypes; let i = index"
                  (change)="togglePerOne($event)"
                  [formControl]="filesFormGroup?.controls[item.type]">
                {{ item.type | documentType }}
              </mat-checkbox>
            </xpo-checkbox-group>
          </div>
        </div>

      </div>
    </div>

    <ng-template #showLoader>
      <div fxLayout="row" fxLayoutAlign="center center" class="resend-invoices-dialog-content">
        <div class="busy-container">
          <xpo-busy-loader></xpo-busy-loader>
        </div>
      </div>
    </ng-template>

  </mat-dialog-content>

  <mat-dialog-actions class="resend-invoices-dialog-actionbar">
    <button mat-flat-button color="primary" (click)="resendClicked()" tabindex="-1" [disabled]="!fg.valid || isLoadingFiles || shouldShowError">
      RESEND
    </button>
    <button mat-stroked-button color="primary" (click)="closeClicked()" tabindex="-1">CANCEL</button>
  </mat-dialog-actions>
</div>

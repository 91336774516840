import { Injectable } from '@angular/core';
import { HttpClient } from '@angular/common/http';

import { XpoLtlDocumentService } from '@xpo-ltl/ngx-ltl';
import { ConfigManagerService } from '@xpo-ltl/config-manager';
import { ApiRequest, DataApiService } from '@xpo-ltl/data-api';

import { DocumentSearch, GetDocumentResp, SearchDmsDocumentResp } from '@xpo-ltl/sdk-documentmanagement';

import * as _ from 'lodash';
import { get as _get, maxBy as _maxBy, size as _size } from 'lodash';

import { BehaviorSubject, Observable, of, Subject } from 'rxjs';
import { catchError, concatMap, map, pluck, switchMap, take, tap } from 'rxjs/operators';

import { DmsFile } from './model/dms-file';
import { DmsAccessToken } from './model/dms-access-token';
import { DmsDocumentRqst } from './model/dms-document-rqst';
import { DocumentRequest } from './model/dms-document-response';

import { ConfigManagerProperties, DocType } from '@shared/enums';
import { DmsGenericResponse } from './model/dms-generic-response';

@Injectable({ providedIn: 'root' })
export class DmsApiService {
  private apiUrl: string;
  private dmsApiEndpoint: string;
  private dmsAccessToken: DmsAccessToken;

  constructor(
    private http: HttpClient,
    private dataManager: DataApiService,
    private config: ConfigManagerService,
    private xpoLtlDocumentService: XpoLtlDocumentService
  ) {
    this.dmsApiEndpoint = config.getSetting(ConfigManagerProperties.dmsApiEndpoint);
    this.apiUrl = config.getSetting(ConfigManagerProperties.apiUrl);
  }

  private generateFullyQualifiedUrl(url: string = ''): string {
    const conditionedApiRoot = `${this.apiUrl}${this.apiUrl.endsWith('/') ? '' : '/'}`;
    const conditionedServiceUri = `${url.startsWith('/') ? url.substring(1) : url}`;
    return `${conditionedApiRoot}${conditionedServiceUri}`;
  }

  private authenticateDms(): Observable<DmsAccessToken> {
    const requestUri = `${this.dmsApiEndpoint}/oauth/dmstoken`;
    const request = new ApiRequest(requestUri);
    if (this.dmsAccessToken && !this.isAuthTokenExpired()) {
      return of(this.dmsAccessToken);
    }

    return this.dataManager.post(request).pipe(
      tap((response) => {
        this.dmsAccessToken = response.data ? (response.data as DmsAccessToken) : (response as DmsAccessToken);
        this.dmsAccessToken.expiresAt = new Date(Date.now() + this.dmsAccessToken.expires_in * 1000);
      }),
      map((response) => {
        return response.data || response;
      })
    );
  }

  private isAuthTokenExpired(): boolean {
    return new Date() > this.dmsAccessToken.expiresAt;
  }

  private getDmsDocument2(request: DmsDocumentRqst): Observable<GetDocumentResp> {
    return this.authenticateDms().pipe(
      take(1),
      switchMap((token) => {
        const requestUri = this.generateFullyQualifiedUrl(
          `${this.dmsApiEndpoint}/document/${request.corpCode}/${request.docClass}/${request.archiveTimestamp}/pdf`
        );
        return this.http
          .get(requestUri, {
            headers: { DMSAuth: token.access_token },
          })
          .pipe(
            map((response: DmsGenericResponse) => {
              return response.data || response;
            })
          );
      })
    );
  }

  getDmsDocument(documentRequest: DocumentRequest): Observable<DmsFile> {
    const mySubject = new BehaviorSubject<DmsFile>(null);
    const { referenceNumber, minDateTime, maxDateTime } = documentRequest;
    let archiveTimestamp;
    let listDocuments$: Observable<SearchDmsDocumentResp>;

    if (documentRequest.docType === DocType.InvoicePdf) {
      listDocuments$ = of(null);
    } else {
      listDocuments$ = this.listAvailableDocuments$(referenceNumber, minDateTime, maxDateTime);
    }

    listDocuments$
      .pipe(
        concatMap((documentListResponse: SearchDmsDocumentResp) => {
          if (!documentListResponse) {
            archiveTimestamp = documentRequest.referenceNumber;
          } else {
            let files = documentListResponse.documentInfo.filter(
              (dmsFile: DocumentSearch) =>
                dmsFile.cdt.docClass === documentRequest.docType && new Date(dmsFile.timestampISO) < maxDateTime
            );
            files = _.orderBy(files, ['timestampISO'], ['desc']);
            archiveTimestamp = files[0].cdt.timestamp;
          }

          const request = {
            corpCode: this.config.getSetting<string>(ConfigManagerProperties.imageCorpCode),
            docClass: documentRequest.docType,
            archiveTimestamp,
          };
          return this.getDmsDocument2(request);
        }),
        take(1)
      )
      .subscribe(
        (success) => {
          const file = this.dataURLtoUint8Array(`data:${success.contentType};base64,${success.documentData}`);
          mySubject.next({ fileSize: null, file });
        },
        (error) => {
          if (_.result(error, 'error.errorCode', undefined)) {
            mySubject.error(`${_.result(error, 'error.message', '')}`);
          } else {
            mySubject.error('No data found');
          }
        }
      );

    return mySubject.asObservable();
  }

  hasDmsDocument(documentRequest: { referenceNumber: string; docType: DocType }): Observable<GetDocumentResp> {
    const request = {
      corpCode: this.config.getSetting<string>(ConfigManagerProperties.imageCorpCode),
      docClass: this.config.getSetting<string>(ConfigManagerProperties.imageDocClass),
      archiveTimestamp: documentRequest.referenceNumber,
    };

    return this.getDmsDocument2(request).pipe(
      take(1),
      catchError((error) => {
        return of(undefined);
      })
    );
  }

  hasImagingDocument(proNumber: string, imageTypes: string[]): Observable<any> {
    return this.xpoLtlDocumentService.listAvailableDocuments(proNumber).pipe(
      take(1),
      pluck('documentInfo'),
      map((results) => {
        const initialValue = {};
        const filteredDocuments = results
          .filter((doc) => imageTypes.includes(doc.cdt.docClass))
          .map((doc) => ({ [doc.cdt.docClass]: true }));

        filteredDocuments.forEach((key) => {
          const prop = Object.entries(key);
          initialValue[`${prop[0][0]}`] = true;
        });

        return initialValue;
      }),
      catchError((error) => {
        return of(undefined);
      })
    );
  }

  listAvailableDocuments$(
    proNumber: string,
    minDateTime: Date = null,
    maxDateTime: Date = null
  ): Observable<SearchDmsDocumentResp> {
    return this.xpoLtlDocumentService.listAvailableDocuments(proNumber, minDateTime, maxDateTime).pipe(
      take(1),
      catchError((error) => {
        return of(undefined);
      })
    );
  }

  dataURLtoUint8Array(dataurl: string): Uint8Array {
    const arr = dataurl.split(',');
    const bstr = atob(arr[1]);
    let n = bstr.length;
    const u8arr = new Uint8Array(n);

    while (n--) {
      u8arr[n] = bstr.charCodeAt(n);
    }

    return u8arr;
  }
}

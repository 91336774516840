import { HttpClient } from '@angular/common/http';
import { DomSanitizer } from '@angular/platform-browser';
import { FormBuilder, FormControl } from '@angular/forms';
import { Router, Event, NavigationStart } from '@angular/router';
import { Component, OnInit, OnDestroy, ViewEncapsulation, HostListener } from '@angular/core';

import { MatIconRegistry } from '@angular/material/icon';

import { Subject, forkJoin } from 'rxjs';
import { debounceTime, take, takeUntil, finalize } from 'rxjs/operators';

import { Invoice } from '@xpo-ltl/sdk-invoice';
import { NotificationService } from '@xpo-ltl/data-api';
import { ConfigManagerService } from '@xpo-ltl/config-manager';
import { FormatValidationService } from '@xpo-ltl/common-services';
import { XpoAccountPopoverConfig, XpoAppSwitcherApplication } from '@xpo-ltl/ngx-ltl-core';

import { RouterUriComponents, AppToolbarFormFields, ConfigManagerProperties } from '@shared/enums';
import { AppHeaderLabels } from '@core/enums/app-header-labels.enum';

import { AppConstantsService } from '@shared/services/app-constants/app-constants.service';
import { ResendInvoiceService } from '@shared/services/resend-invoices/resend-invoice.service';
import { InvoiceAuditorService } from '@shared/services/invoice-auditor/invoice-auditor.service';
import { PaymentHistoryService } from '@shared/services/payment-history/payment-history.service';
import { TransmissionHistoryService } from '@shared/services/transmission-history/transmission-history.service';
import { XpoLtlAuthenticationService } from '@xpo-ltl/ngx-auth';

@Component({
  selector: 'app-root',
  templateUrl: './app.component.html',
  styleUrls: ['./app.component.scss'],
  encapsulation: ViewEncapsulation.None,
})
export class AppComponent implements OnInit, OnDestroy {
  private isSearching = false;
  private unsubscribe$ = new Subject<void>();

  appToolbarFormFields = AppToolbarFormFields;
  isValidProNumber = false;
  hideProSearchComponent = false;
  isProduction = false;
  isResend = false;

  invoiceSearchForm = this.fb.group({
    [AppToolbarFormFields.UserQuery]: new FormControl(''),
  });

  apps: XpoAppSwitcherApplication[];
  accountPopoverProfileConfig: XpoAccountPopoverConfig;
  notifications: any[];

  appHeaderLabels = AppHeaderLabels;
  routerUriComponents = RouterUriComponents;

  isViewInvoiceVisible = false;
  isReleaseInvoicesVisible = false;
  isResendInvoicesVisible = false;
  isRFCManagementVisible = false;
  hideReleaseInvoicesTab: boolean;
  hideResendInvoicesTab: boolean;
  hideRFCManagementTab: boolean;

  constructor(
    private router: Router,
    private fb: FormBuilder,
    private http: HttpClient,
    private sanitizer: DomSanitizer,
    private config: ConfigManagerService,
    private iconRegistry: MatIconRegistry,
    private appConstantsService: AppConstantsService,
    private authService: XpoLtlAuthenticationService,
    private notificationService: NotificationService,
    private formatValidation: FormatValidationService,
    private configManagerService: ConfigManagerService,
    private resendInvoiceService: ResendInvoiceService,
    public invoiceAuditorService: InvoiceAuditorService,
    private paymentHistoryService: PaymentHistoryService,
    private transmissionHistoryService: TransmissionHistoryService
  ) {}

  setRegion(): void {
    const region = this.configManagerService.getSetting<string>(ConfigManagerProperties.region);
    this.authService
      .initAuthSetup$(region)
      .pipe(take(1))
      .subscribe();
  }

  ngOnInit() {
    this.setRegion();
    this.registerSvgIcons();
    this.setConfigurationSettings();

    this.router.events.subscribe((event: Event) => {
      if (event instanceof NavigationStart) {
        this.isResend = !!event.url.endsWith('resend');
      }
    });

    this.invoiceSearchForm
      .get(AppToolbarFormFields.UserQuery)
      .valueChanges.pipe(debounceTime(100), takeUntil(this.unsubscribe$))
      .subscribe((pro) => {
        this.isValidProNumber = pro && pro.length >= 9 && this.formatValidation.isValidProNumber(pro);

        if (this.isValidProNumber) {
          this.searchPRO();
        }
      });

    this.invoiceAuditorService.roles$.pipe(takeUntil(this.unsubscribe$)).subscribe((roles: string[]) => {
      this.isViewInvoiceVisible = this.invoiceAuditorService.isViewInvoiceTabVisible(roles);
      this.isRFCManagementVisible = this.invoiceAuditorService.isRFCManagementTabVisible(roles);
      this.isResendInvoicesVisible = this.invoiceAuditorService.isResendInvoicesTabVisible(roles);
      this.isReleaseInvoicesVisible = !this.invoiceAuditorService.isReleaseInvoicesTabHidden(roles);
      this.isReleaseInvoicesVisible = this.invoiceAuditorService.isReleaseInvoicesTabVisible(roles);
    });

    this.appConstantsService.invoiceReadMode.subscribe((value) => {
      this.hideProSearchComponent = value;
      this.hideReleaseInvoicesTab = value;
      this.hideResendInvoicesTab = value;
      this.hideRFCManagementTab = value;
    });
  }

  @HostListener('window:keyup.enter')
  searchPRO() {
    if (this.isSearching) {
      return;
    }

    const userQueryControl = this.invoiceSearchForm.get(AppToolbarFormFields.UserQuery);

    this.isSearching = true;

    if (userQueryControl.value === 'test' && !this.config.getSetting<boolean>(ConfigManagerProperties.production)) {
      this.handleTestInvoice();

      userQueryControl.setValue('');

      this.isSearching = false;
    } else if (userQueryControl.value) {
      if (this.formatValidation.isValidProNumber(userQueryControl.value)) {
        forkJoin({
          obs1$: this.transmissionHistoryService.getTransmissionHistory(userQueryControl.value),
          obs2$: this.invoiceAuditorService.handleInvoiceSearchAndNavigate(userQueryControl.value),
          obs3$: this.resendInvoiceService.setResendVisibility(userQueryControl.value),
        })
          .pipe(
            takeUntil(this.unsubscribe$),
            finalize(() => {
              this.isSearching = false;
            })
          )
          .subscribe((res: any) => {
            this.paymentHistoryService.getPaymentHistory(userQueryControl.value);
            userQueryControl.setValue('');
          });
      } else {
        this.notificationService.showSnackBarMessage(`${userQueryControl.value} is not a valid PRO number`, {
          durationInMillis: this.config.getSetting<number>(ConfigManagerProperties.errorToastDuration),
          status: 'error',
        });
        this.isSearching = false;
      }
    } else {
      this.isSearching = false;
    }
  }

  private registerSvgIcons() {
    this.iconRegistry.addSvgIcon(
      'xpo-logo',
      this.sanitizer.bypassSecurityTrustResourceUrl('assets/images/xpo_logo.svg')
    );

    this.iconRegistry.addSvgIcon(
      'xpo-check',
      this.sanitizer.bypassSecurityTrustResourceUrl('assets/images/check_circle.svg')
    );

    this.iconRegistry.addSvgIcon(
      'xpo-uncheck',
      this.sanitizer.bypassSecurityTrustResourceUrl('assets/images/uncheck_circle.svg')
    );

    this.iconRegistry.addSvgIcon(
      'xpo-error',
      this.sanitizer.bypassSecurityTrustResourceUrl('assets/images/error_circle.svg')
    );
  }

  private setConfigurationSettings() {
    this.isProduction = this.config.getSetting<boolean>(ConfigManagerProperties.production);
  }

  private handleTestInvoice() {
    // Use mock data when the PRO is "test"
    let invoiceData: Invoice;
    invoiceData = new Invoice();
    this.http.get<any>('../assets/mock-data/invoices.json').subscribe((data: { invoices: Invoice[] }) => {
      const returnedTarget = Object.assign(invoiceData, data.invoices[0]);
      this.invoiceAuditorService.setCurrentInvoice(returnedTarget);
    });
  }

  ngOnDestroy() {
    this.unsubscribe$.next();
    this.unsubscribe$.complete();
    this.unsubscribe$ = null;
  }
}

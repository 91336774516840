import { Component, Inject } from '@angular/core';
import { MAT_DIALOG_DATA, MatDialogRef } from '@angular/material/dialog';

import { ChargeToCd } from '@xpo-ltl/sdk-common';
import { ConditioningService } from '@xpo-ltl/common-services';
import { Invoice, InvoiceSummary } from '@xpo-ltl/sdk-invoice';

import { BothBillDataInterface } from '@shared/models';

@Component({
  selector: 'app-both-bill-dialog',
  templateUrl: './both-bill-dialog.component.html',
  styleUrls: ['./both-bill-dialog.component.scss'],
})
export class BothBillDialogComponent {
  readonly proNumber: string;
  readonly invoices: Invoice[];
  readonly invoiceSummaries: InvoiceSummary[];
  ChargeToCd = ChargeToCd;

  constructor(
    @Inject(MAT_DIALOG_DATA) public data: BothBillDataInterface,
    private dialogRef: MatDialogRef<BothBillDialogComponent>,
    private conditioningService: ConditioningService
  ) {
    this.proNumber = this.conditioningService.conditionProNumber(data.proNumber, 10);
    this.invoices = this.data.invoices;
    this.invoiceSummaries = this.data.invoiceSummaries;
  }
  handleSelectItemClicked(type: ChargeToCd) {
    if (this.invoices) {
      this.dialogRef.close(this.invoices.find((invoice) => invoice.invoiceHeader.chargeToCd === type));
    } else {
      this.dialogRef.close(this.invoiceSummaries.find((invoiceSummary) => invoiceSummary.chargeToCd === type));
    }
  }
}

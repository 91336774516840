<div class="release-invoices-dialog">
  <h2 mat-dialog-title class="release-invoices-dialog-header">
    Release Invoices?
    <xpo-dialog-close-button></xpo-dialog-close-button>
  </h2>
  <mat-dialog-content class="release-invoices-dialog-content">
    Do you really want to release {{ invoicesToRelease }}?
  </mat-dialog-content>
  <mat-dialog-actions class="release-invoices-dialog-actionbar">
    <button mat-flat-button color="primary" [mat-dialog-close]="true" tabindex="-1">RELEASE</button>
    <button mat-stroked-button color="primary" [mat-dialog-close]="false" tabindex="-1">CANCEL</button>
  </mat-dialog-actions>
</div>

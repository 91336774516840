import { Pipe, PipeTransform } from '@angular/core';

@Pipe({
  name: 'countFiles'
})
export class CountFilesPipe implements PipeTransform {

  transform(value: any, ...args: any[]): any {

    let count = 0;
    const availableFiles = args[0];

    Object.entries(availableFiles).forEach(pro => {
      Object.entries(pro[1]).forEach((type: any) => {
        if (value === type[1].file) {
          count++;
        }
      });
    });

    return count > 1 ? `(${count})` : null;
  }

}

import { BrowserModule } from '@angular/platform-browser';
import { NgModule, CUSTOM_ELEMENTS_SCHEMA } from '@angular/core';
import { CurrencyPipe, DatePipe, DecimalPipe } from '@angular/common';
import { BrowserAnimationsModule } from '@angular/platform-browser/animations';

import {
  XpoShellModule,
  XpoDialogModule,
  XpoFeedbackModule,
  XpoSnackBarModule,
  XpoBrowserGuardModule,
  XpoPageNotFoundRoutingModule,
} from '@xpo-ltl/ngx-ltl-core';
import { UserApiService } from '@xpo-ltl/sdk-user';
import { XpoLtlEmailService } from '@xpo-ltl/ngx-ltl';
import { InvoiceApiService } from '@xpo-ltl/sdk-invoice';
import { CustomerApiService } from '@xpo-ltl/sdk-customer';
import { ShipmentApiService } from '@xpo-ltl/sdk-shipment';
import { ShipmentOdsApiService } from '@xpo-ltl/sdk-shipmentods';
import { InfrastructureApiService } from '@xpo-ltl/sdk-infrastructure';
import { DataApiService, NotificationService } from '@xpo-ltl/data-api';
import { OrderManagementApiService } from '@xpo-ltl/sdk-ordermanagement';
import { LoggingApiService, SdkLoggingModule } from '@xpo-ltl/sdk-logging';
import { AccountsReceivableApiService } from '@xpo-ltl/sdk-accountsreceivable';
import { ConditioningService, FormatValidationService } from '@xpo-ltl/common-services';
import {
  ConfigManagerModule,
  ConfigManagerService,
  CONFIG_MANAGER_SERVICE_CONFIGURATION,
} from '@xpo-ltl/config-manager';

import { ClipboardModule, ClipboardService } from 'ngx-clipboard';

import { CoreModule } from '@core/core.module';
import { SharedModule } from '@shared/shared.module';
import { AppRoutingModule } from './app-routing.module';
import { AngularFireModule } from '@angular/fire';
import { AppComponent } from './app.component';
import { XpoAuthConfig, XPO_AUTH_CONFIG } from '@xpo/ngx-auth';
import { XpoLtlAuthConfigLoaderService, XpoLtlAuthenticationService, XpoLtlAuthModule } from '@xpo-ltl/ngx-auth';
import { environment } from 'environments/environment';
import { HttpClientModule } from '@angular/common/http';
import { InvoiceAuditorService } from '@shared/services/invoice-auditor/invoice-auditor.service';

export function authConfigFactory(
  configService: XpoLtlAuthConfigLoaderService
): XpoAuthConfig {
  configService.initSettings('./assets/config.json');

  return new XpoAuthConfig({
    appCode: configService.appCode,
    scopes: configService.scopes,
    isProd: configService.isProd,
    applicationRootUri: configService.appRootUri,
  });
}

@NgModule({
  declarations: [AppComponent],
  imports: [
    AngularFireModule.initializeApp(environment.firebase),
    // Angular
    BrowserModule,
    BrowserAnimationsModule,

    // XPO
    XpoShellModule,
    XpoDialogModule,
    SdkLoggingModule,
    XpoSnackBarModule,
    XpoFeedbackModule,
    ConfigManagerModule,
    XpoBrowserGuardModule,

    // local
    CoreModule,
    SharedModule,

    // others
    ClipboardModule,
    HttpClientModule,

    // Auth
    XpoLtlAuthModule.forRoot({
      provide: XPO_AUTH_CONFIG,
      useFactory: authConfigFactory,
      deps: [XpoLtlAuthConfigLoaderService, InvoiceAuditorService],
    }),
    AppRoutingModule, // Keep last
    XpoPageNotFoundRoutingModule, // Keep laster
  ],
  providers: [
    { provide: CONFIG_MANAGER_SERVICE_CONFIGURATION, useValue: { configFileUrl: './assets/config.json' } },
    DatePipe,
    DecimalPipe,
    CurrencyPipe,
    DataApiService,
    UserApiService,
    ClipboardService,
    InvoiceApiService,
    LoggingApiService,
    ShipmentApiService,
    CustomerApiService,
    XpoLtlEmailService,
    NotificationService,
    ConditioningService,
    ConfigManagerService,
    ShipmentOdsApiService,
    FormatValidationService,
    InfrastructureApiService,
    OrderManagementApiService,
    XpoLtlAuthenticationService,
    AccountsReceivableApiService,
  ],
  schemas: [CUSTOM_ELEMENTS_SCHEMA],
  exports: [],
  bootstrap: [AppComponent],
})
export class AppModule {}

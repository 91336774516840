import { PipeTransform, Pipe } from '@angular/core';

@Pipe({
  name: 'pastFuturePipe',
})
export class PastFuturePipe implements PipeTransform {
  transform(value: Date, pastLabel: string, futureLabel: string): any {
    if (value) {
      if (value >= new Date()) {
        return futureLabel;
      }
      return pastLabel;
    }
  }
}

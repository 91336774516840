import { Pipe, PipeTransform } from '@angular/core';

@Pipe({
  name: 'currencyCode',
})
export class CurrencyCodePipe implements PipeTransform {
  transform(value: string, args?: any): any {
    if (!value || value.toLowerCase() === 'usdollars') {
      return 'USD';
    } else if (value.toLowerCase() === 'canadiandollars') {
      return 'CAD';
    }
    return value;
  }
}

import { FormBuilder, FormGroup, Validators } from '@angular/forms';
import { MAT_DIALOG_DATA, MatDialogRef } from '@angular/material/dialog';
import { Component, Inject, OnDestroy, OnInit, ViewEncapsulation } from '@angular/core';

import {
  XrtAttributeFilter,
  XrtSearchQueryHeader,
  XrtCustomerSearchRqst,
  XrtCustomerSearchFilter,
  ElasticSearchApiService,
} from '@xpo-ltl/sdk-elasticsearch';
import { FormatValidationService } from '@xpo-ltl/common-services';

import * as _ from 'lodash';
import { Observable, Subject } from 'rxjs';

import { AddRfcDialogDataInterface } from '@shared/models/add-rfc-dialog-data';

import { RfcDialogFormFields } from '@shared/enums/rfc-dialog-form-fields.enum';
import { madCodeValidator, RemoteMadcodeValidator } from '@shared/validators/madcode-validator/madcode.validator';

export interface SearchMadCdResponse {
  header: any;
  result: any[];
  madcode: string;
}

@Component({
  selector: 'app-add-rfc-dialog',
  templateUrl: './add-rfc-dialog.component.html',
  styleUrls: ['./add-rfc-dialog.component.scss'],
  encapsulation: ViewEncapsulation.None,
})
export class AddRfcDialogComponent implements OnInit, OnDestroy {

  constructor(
    private formBuilder: FormBuilder,
    private elasticsearchService: ElasticSearchApiService,
    private dialogRef: MatDialogRef<AddRfcDialogComponent>,
    private remoteMadcodeValidator: RemoteMadcodeValidator,
    private formatValidationService: FormatValidationService,
    @Inject(MAT_DIALOG_DATA) public data: AddRfcDialogDataInterface
  ) {}

  addRfcFormFields = RfcDialogFormFields;
  formGroup: FormGroup;
  customerNbr: string;

  private unsubscriber = new Subject<void>();

  ngOnInit() {
    this.formGroup = this.formBuilder.group({
      [RfcDialogFormFields.MADCODE]: [
        '',
        {
          validators: [Validators.required, madCodeValidator(this.formatValidationService, this.data)],
          asyncValidators: [this.remoteMadcodeValidator.existingMadcodeValidator()],
          updateOn: 'blur',
        },
      ],
      [RfcDialogFormFields.NAME]: ['', Validators.required],
      [RfcDialogFormFields.ADDRESS]: ['', Validators.required],
      [RfcDialogFormFields.RFC_NUMBER]: ['', [Validators.required, Validators.maxLength(20)]],
    });

    this.onValueChanges();
  }

  hasError(error: string, field: RfcDialogFormFields): boolean {
    return this.formGroup.get(field).hasError(error) && this.formGroup.get(field).touched;
  }

  onValueChanges(): void {
    this.formGroup.get(RfcDialogFormFields.MADCODE).statusChanges.subscribe((res) => {
      if (res === 'VALID') {
        this.xrtCustomerSearch(this.formGroup.get(RfcDialogFormFields.MADCODE).value).subscribe((customerResult) => {
          if (customerResult && customerResult.result.length) {
            const customer = customerResult.result[0];
            this.customerNbr = customer.acctInstId;
            const address = `${customer.addressLine}, ${customer.cityName} (${customer.stateCd})`;
            this.formGroup.get(RfcDialogFormFields.NAME).setValue(customer.name1);
            this.formGroup.get(RfcDialogFormFields.ADDRESS).setValue(address);
          } else {
            this.formGroup.get(RfcDialogFormFields.NAME).setValue(null);
            this.formGroup.get(RfcDialogFormFields.ADDRESS).setValue(null);
          }
        });
      } else {
        this.formGroup.get(RfcDialogFormFields.NAME).setValue(null);
        this.formGroup.get(RfcDialogFormFields.ADDRESS).setValue(null);
      }
    });
  }

  private xrtCustomerSearch(madcode: string): Observable<any> {
    const options = new XrtCustomerSearchRqst();
    options.header = new XrtSearchQueryHeader();
    options.filter = new XrtCustomerSearchFilter();
    options.header.page = 1;
    options.header.pageSize = 1;
    options.header.sortExpressions = [{ IsDescendingSort: true, Column: 'seqNbr', direction: 'asc' }];
    options.filter.acctMadCd = new XrtAttributeFilter();
    options.filter.acctMadCd.equals = madcode;
    return this.elasticsearchService.xrtCustomerSearch(options);
  }

  ngOnDestroy() {
    this.unsubscriber.next();
    this.unsubscriber.complete();
  }

  closeClicked() {
    this.dialogRef.close();
  }

  saveClicked() {
    if (this.formGroup.valid) {
      const rfcData = this.formGroup.getRawValue();
      rfcData.customerNbr = this.customerNbr;
      this.dialogRef.close(rfcData);
    }
  }
}

import { MatDialog } from '@angular/material/dialog';
import { Component, OnInit, ViewEncapsulation } from '@angular/core';

import { XpoFeedback } from '@xpo-ltl/ngx-ltl-core';
import { ConfigManagerService } from '@xpo-ltl/config-manager';

import { take } from 'rxjs/operators';
import { detect } from 'detect-browser';

import { ConfigManagerProperties } from '@shared/enums';
import { FeedbackService } from '@shared/services/feedback/feedback.service';

@Component({
  selector: 'app-footer',
  templateUrl: './app-footer.component.html',
  styleUrls: ['./app-footer.component.scss'],
  encapsulation: ViewEncapsulation.None,
})
export class AppFooterComponent implements OnInit {

  browserInfo;
  copyrightYear: number;

  constructor(
    private dialog: MatDialog,
    public feedbackService: FeedbackService,
    private configManagerService: ConfigManagerService) {
    this.copyrightYear = new Date().getFullYear();
  }

  get buildVersion(): string {
    return this.configManagerService.getSetting<string>(ConfigManagerProperties.buildVersion);
  }

  ngOnInit(): void {
    this.browserInfo = detect();
  }

  feedbackClicked() {
    this.feedbackService.getFeedbackConfig$().pipe(take(1)).subscribe((config) => {
      this.dialog.open(XpoFeedback, { data: config });
    });
  }
}

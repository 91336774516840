<div container="resend-invoices-dialog" class="resend-invoices-dialog">
  <h1 mat-dialog-title *ngIf="isMultiple"><mat-icon>warning</mat-icon> There are closed invoices in your selection. How would you like to proceed?</h1>
  <h1 mat-dialog-title *ngIf="!isMultiple"><mat-icon>warning</mat-icon> The selected invoice is close. Do you want to resend it?</h1>

  <mat-dialog-actions class="resend-invoices-dialog-actionbar">
    <div>
      <button mat-flat-button color="primary" (click)="resendAllClicked()" tabindex="-1">
        RESEND {{ isMultiple ? 'ALL' : '' }}
      </button>
      <button mat-stroked-button color="primary" (click)="resendOpenClicked()" tabindex="-1" *ngIf="isMultiple" [disabled]="!hasOpenInvoices">
        RESEND OPEN
      </button>
    </div>
    <button mat-stroked-button color="primary" (click)="closeClicked()" tabindex="-1">CANCEL</button>
  </mat-dialog-actions>
</div>

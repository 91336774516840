<div [formGroup]="formGroup" container="rfc-management-dialog">
  <h2 mat-dialog-title class="rfc-management-dialog-header">Edit Reference Data</h2>
  <mat-dialog-content>
    <div fxLayout="row" fxLayoutAlign="start start" class="rfc-management-dialog-content">

      <div
        fxLayout="column"
        fxFlex="100"
        class="rfc-management-dialog-content-right"
      >
        <ng-container *ngIf="data.isInvoice">
          <div fxLayout="row" fxLayoutAlign="stretch start" class="info">
            <mat-chip xpoTag xpoChipColor="#0091EA">INFO</mat-chip>
            Invoices for related PROs will be transmitted automatically on saving
          </div>
          <hr />
        </ng-container>

        <div fxLayout="row" fxLayoutAlign="stretch start">
          <mat-form-field fxFill="">
            <input
              type="text"
              matInput
              placeholder="MAD Code"
              maxlength="20"
              [readonly]="data.isInvoice"
              [formControlName]="editRfcFormFields.MADCODE"
            />
            <mat-error>
              <div class="mat-error" *ngIf="hasError('required', editRfcFormFields.MADCODE)">Required</div>
              <div class="mat-error" *ngIf="hasError('madCode', editRfcFormFields.MADCODE)">
                Invalid format
              </div>
              <div class="mat-error" *ngIf="hasError('invalidAsyncMadCode', editRfcFormFields.MADCODE)">
                Invalid MAD Code
              </div>
              <div class="mat-error" *ngIf="hasError('invalidAsyncMXMadCode', editRfcFormFields.MADCODE)">
                MAD Code address must be in Mexico
              </div>
              <div class="mat-error" *ngIf="hasError('madCodeIsAlreadyPresent', editRfcFormFields.MADCODE)">
                Record already exists for this MAD Code. Please update the existing record.
              </div>
            </mat-error>
          </mat-form-field>
        </div>

        <div fxLayout="row" fxLayoutAlign="stretch start">
          <mat-form-field fxFill="">
            <input
              type="text"
              matInput
              placeholder="Name"
              maxlength="100"
              readonly="readonly"
              [formControlName]="editRfcFormFields.NAME"
            />
            <mat-error>
              <div class="mat-error" *ngIf="hasError('required', editRfcFormFields.NAME)">Required</div>
            </mat-error>
          </mat-form-field>
        </div>

        <div fxLayout="row" fxLayoutAlign="stretch start">
          <mat-form-field fxFill="">
            <input
              type="text"
              matInput
              placeholder="Address"
              readonly="readonly"
              [formControlName]="editRfcFormFields.ADDRESS"
            />
            <mat-error>
              <div class="mat-error" *ngIf="hasError('required', editRfcFormFields.ADDRESS)">Required</div>
            </mat-error>
          </mat-form-field>
        </div>

        <div fxLayout="row" fxLayoutAlign="stretch start">
          <mat-form-field fxFill="">
            <input
              type="text"
              matInput
              placeholder="RFC Number"
              [formControlName]="editRfcFormFields.RFC_NUMBER"
            />
            <mat-error>
              <div class="mat-error" *ngIf="hasError('required', editRfcFormFields.RFC_NUMBER)">Required</div>
            </mat-error>
            <mat-error>
              <div class="mat-error" *ngIf="hasError('maxlength', editRfcFormFields.RFC_NUMBER)">The RFC Number entered exceeds the maximun length</div>
            </mat-error>
          </mat-form-field>
        </div>
      </div>

    </div>
  </mat-dialog-content>

  <mat-dialog-actions class="rfc-management-dialog-actionbar">
    <button mat-button color="primary" (click)="closeClicked()" tabindex="-1">Cancel</button>
    <button mat-button color="primary" (click)="saveClicked()" tabindex="-1" [disabled]="!formGroup.valid">
      Save
    </button>
  </mat-dialog-actions>
</div>

<span mat-dialog-title class="invoice-not-found__error-header">{{ errorTitle }}</span>
<mat-dialog-content>
  <span class="invoice-not-found__error-message">{{ errorMsg }}</span>
</mat-dialog-content>
<mat-dialog-actions>
  <button
    tabindex="0"
    ng-focus="true"
    class="invoice-not-found__error-actions"
    fxFlexAlign="end"
    mat-button
    color="primary"
    [mat-dialog-close]="true"
  >
    OK
  </button>
</mat-dialog-actions>

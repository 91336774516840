import { NgModule } from '@angular/core';
import { CommonModule } from '@angular/common';

import { XpoLtlPipeModule } from '@xpo-ltl/ngx-ltl';

import { SrnPipe } from './srn/srn.pipe';
import { RatePipe } from './rate/rate.pipe';
import { HyphenPipe } from './hyphen/hyphen.pipe';
import { MadCodePipe } from './mad-code/mad-code.pipe';
import { DiscountPipe } from './discount/discount.pipe';
import { TotalRatePipe } from './total-rate/total-rate.pipe';
import { DebtorTypePipe } from './debtor-type/debtor-type.pipe';
import { PastFuturePipe } from './past-future/past-future.pipe';
import { CountFilesPipe } from './count-files/count-files.pipe';
import { ChargeToCdPipe } from './charge-to-cd/charge-to-cd.pipe';
import { CurrencyCodePipe } from './currency-code/currency-code.pipe';
import { DocumentTypePipe } from './document-type/document-type.pipe';
import { MillisToDaysPipe } from './millis-to-days/millis-to-days.pipe';
import { EmptyCurrencyPipe } from './empty-currency/empty-currency.pipe';
import { TransmitStatusCdPipe } from './transmit-status-cd/transmit-status-cd.pipe';
import { ProNumberFormatterPipe } from './pro-number-formatter/pro-number-formatter.pipe';
import { InvoiceLineDescriptionPipe } from './invoice-line-description/invoice-line-description.pipe';
import { NegativeCurrencyParensPipe } from './negative-currency-parens/negative-currency-parens.pipe';
import { InvoiceShipmentPartyCityStateZipPipe } from './invoice-shipment-party-city-state-zip/invoice-shipment-party-city-state-zip.pipe';

const pipeModules = [
  SrnPipe,
  RatePipe,
  HyphenPipe,
  MadCodePipe,
  DiscountPipe,
  TotalRatePipe,
  ChargeToCdPipe,
  CountFilesPipe,
  DebtorTypePipe,
  PastFuturePipe,
  DocumentTypePipe,
  MillisToDaysPipe,
  CurrencyCodePipe,
  EmptyCurrencyPipe,
  TransmitStatusCdPipe,
  ProNumberFormatterPipe,
  NegativeCurrencyParensPipe,
  InvoiceLineDescriptionPipe,
  InvoiceShipmentPartyCityStateZipPipe,
];

@NgModule({
  declarations: pipeModules,
  imports: [CommonModule, XpoLtlPipeModule],
  exports: [pipeModules, XpoLtlPipeModule],
  providers: pipeModules,
})
export class PipeModule {}

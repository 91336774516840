import { DatePipe } from '@angular/common';
import { Injectable } from '@angular/core';

import { sortBy } from 'lodash';
import { BehaviorSubject, Observable, of } from 'rxjs';
import { take, map, tap, catchError } from 'rxjs/operators';

import {
  Invoice,
  InvoiceApiService,
  ListInvoiceTransmissionHistoryPath,
  ListInvoiceTransmissionHistoryQuery,
  ListInvoiceTransmissionHistoryResp,
} from '@xpo-ltl/sdk-invoice';
import { ConditioningService } from '@xpo-ltl/common-services';

import { CriticalTransmissionDate } from '../../enums/critical-transmission-dates.enum';

@Injectable({
  providedIn: 'root',
})
export class TransmissionHistoryService {

  private readonly _transmissionHistory = new BehaviorSubject<Invoice[]>([]);

  get transmissionHistory$(): Observable<Invoice[]> {
    return this._transmissionHistory.asObservable();
  }

  get transmissionHistory(): Invoice[] {
    return this._transmissionHistory.getValue();
  }

  constructor(
    private datePipe: DatePipe,
    private invoiceApiService: InvoiceApiService,
    private conditioningService: ConditioningService,
  ) {}

  initTransmissionHistory(pro: string): void {
    const pathParams = new ListInvoiceTransmissionHistoryPath();

    pathParams.proNbr = this.conditioningService.conditionProNumber(pro, 11);

    const queryParams = new ListInvoiceTransmissionHistoryQuery();

    const dataOptions = {
      loadingOverlayEnabled: false,
    };

    this.invoiceApiService
      .listInvoiceTransmissionHistory(pathParams, queryParams, dataOptions)
      .pipe(
        take(1),
        map((response: ListInvoiceTransmissionHistoryResp) => {
          // we work only with transmissions through production stream
          return response.invoices.filter((invoice) => {
            invoice.invoiceDetail.deliveryInfo = invoice.invoiceDetail.deliveryInfo.filter(
              (delivery) => !delivery.parallelInd
            );

            return invoice.invoiceDetail.deliveryInfo.length > 0;
          });
        }),
        map((invoices) =>
          invoices.filter((invoice) => this.isNotTrappedDate(invoice.invoiceHeader.scheduledTransmissionDate))
        ),
        map((invoices) => sortBy(invoices, (invoice) => invoice.invoiceHeader.versionSequenceNbr))
      )
      .subscribe((response) => {
        this._transmissionHistory.next(response);
      });
  }

  getTransmissionHistory(pro: string): Observable<boolean> {
    const pathParams = new ListInvoiceTransmissionHistoryPath();

    pathParams.proNbr = this.conditioningService.conditionProNumber(pro, 11);

    const queryParams = new ListInvoiceTransmissionHistoryQuery();

    const dataOptions = {
      loadingOverlayEnabled: true,
    };

    return this.invoiceApiService.listInvoiceTransmissionHistory(pathParams, queryParams, dataOptions).pipe(
      take(1),
      map((response: ListInvoiceTransmissionHistoryResp) => {
        // we work only with transmissions through production stream
        return response.invoices.filter((invoice) => {
          invoice.invoiceDetail.deliveryInfo = invoice.invoiceDetail.deliveryInfo.filter(
            (delivery) => !delivery.parallelInd
            );
            return invoice.invoiceDetail.deliveryInfo.length > 0;
          });
      }),
      map((invoices: Invoice[]) => {
        return invoices.filter((invoice) => this.isNotTrappedDate(invoice.invoiceHeader.scheduledTransmissionDate));
      }),
      map((invoices: Invoice[]) => {
        return sortBy(invoices, (invoice) => invoice.invoiceHeader.versionSequenceNbr);
      }),
      tap((invoices: Invoice[]) => {
        this.setTransmissionHistory(invoices);
      }),
      map(() => {
        return true;
      }),
      catchError(err => {
        return of(false);
      }));
  }

  private setTransmissionHistory(invoices: Invoice[]) {
    this._transmissionHistory.next(invoices);
  }

  private isNotTrappedDate(date: any): boolean {
    const transmitDate = this.datePipe.transform(date, 'MM/dd/yyyy');

    return !(transmitDate === CriticalTransmissionDate.Trapped || transmitDate === CriticalTransmissionDate.Cutoff);
  }
}

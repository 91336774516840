<div class="document-view" fxFlex="" fxLayout="column" [class.has-document]="(documentDetails$ | async).document">
  <h2 mat-dialog-title class="document-view__title" fxLayout="row" fxLayoutAlign="space-between none">
    {{ data.documentType | documentType }}
    <svg
    *ngIf="(documentDetails$ | async).showWait"
    class="spinner"
    width="25px"
    height="25px"
    viewBox="0 0 66 66"
    xmlns="http://www.w3.org/2000/svg"
    >
      <circle class="path" fill="none" stroke-width="6" stroke-linecap="round" cx="33" cy="33" r="30"></circle>
    </svg>
    <button *ngIf="(documentDetails$ | async).document" mat-button xpoDownloadButton [handleDownload]="handleDownloadClicked()"></button>
  </h2>
  <mat-dialog-content fxFlex="">
    <div class="document-view__pdf-container">
      <div *ngIf="(documentDetails$ | async).documentNotFound" class="document-view__document-not-found">
        {{ (documentDetails$ | async).errorMessage }}
      </div>
      <pdf-viewer
        class="document-view__pdf"
        [src]="(documentDetails$ | async).document"
        (after-load-complete)="callBackFn($event)"
        [page]="(documentDetails$ | async).currentPage"
        [show-all]="false"
        [fit-to-page]="true"
        [original-size]="false"
        [render-text]="true"
        [(zoom)]="zoomLevel"
      ></pdf-viewer>
    </div>
  </mat-dialog-content>
  <mat-dialog-actions class="document-view__actions">
    <div fxLayout="row" fxLayoutAlign="stretch start" fxFlexFill="">
      <div fxLayoutAlign="start" class="document-view__navigation-close">
        <mat-slider
          *ngIf="(documentDetails$ | async).document"
          #zoomControl
          min="0"
          max="2"
          step="0.05"
          value="1"
          matTooltip="Zoom"
        ></mat-slider>
      </div>
      <div fxFlex="" fxLayout="row" fxLayoutAlign="center center" class="document-view__navigation-count-container">
        <div
          *ngIf="(documentDetails$ | async).showNavigation"
          class="document-view__navigation"
          fxLayout="row"
          fxLayoutAlign="center"
        >
          <button fxLayoutAlign="center center" mat-button mat-icon-button aria-label="Next Page" (click)="navigate(-1)">
            <mat-icon matSuffix class="document-view__navigation-button">navigate_before</mat-icon>
          </button>
          <div fxLayoutAlign="center center" class="document-view__navigation-counts">
            {{ (documentDetails$ | async).currentPage }} of {{ (documentDetails$ | async).totalPages }}
          </div>
          <button fxLayoutAlign="center center" mat-button mat-icon-button aria-label="Next Page" (click)="navigate(1)">
            <mat-icon matSuffix class="document-view__navigation-button">navigate_next</mat-icon>
          </button>
        </div>
      </div>
      <div fxLayoutAlign="end" class="document-view__navigation-close">
        <button mat-stroked-button [mat-dialog-close]="true">Close</button>
      </div>
    </div>
  </mat-dialog-actions>
</div>

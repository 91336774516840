import { Component, Inject, OnInit } from '@angular/core';
import { MAT_DIALOG_DATA, MatDialogRef } from '@angular/material/dialog';

import { ResendInvoicesDialogDataInterface } from '@shared/models';

@Component({
  selector: 'app-resend-closed-invoices-dialog',
  templateUrl: './resend-closed-invoices-dialog.component.html',
  styleUrls: ['./resend-closed-invoices-dialog.component.scss'],
})
export class ResendClosedInvoicesDialogComponent implements OnInit {

  constructor(
    private dialogRef: MatDialogRef<ResendClosedInvoicesDialogComponent>,
    @Inject(MAT_DIALOG_DATA) public data: ResendInvoicesDialogDataInterface,
  ) {
    this.invoices = data.invoices || [];
  }

  invoices: any[];

  get hasOpenInvoices() {
    return this.invoices.some(invoice => !invoice.isClosed);
  }

  get isMultiple() {
    return this.invoices.length > 1;
  }

  ngOnInit() {
  }

  closeClicked() {
    this.dialogRef.close();
  }

  resendAllClicked() {
    this.dialogRef.close({ invoices: this.invoices });
  }

  resendOpenClicked() {
    const openInvoices = this.invoices.filter(invoice => !invoice.isClosed);
    this.dialogRef.close({ invoices: openInvoices });
  }

}

import { Injectable } from '@angular/core';
import { MatDialog, MatDialogRef } from '@angular/material/dialog';

import {
  Invoice,
  Preference,
  InvoiceInfo,
  ShipmentBasic,
  InvoiceSummary,
  InvoiceApiService,
  InvoiceShipmentParty,
  SubmitResendInvoicesRqst,
  PreferredTransmissionMode,
  DetermineInvoicePreferenceRqst,
  DetermineInvoicePreferenceResp,
  StartInvResendEnChEnsembleRqst,
  StartInvoiceResendEnsemblePayload,
  ListInvoiceSummaryForResendRqst,
  ListInvoiceSummaryForResendResp,
} from '@xpo-ltl/sdk-invoice';
import { AcctBasicDetails, CustomerApiService, GetAcctBasicDetailsResp, GetAcctBasicDetailsRqst } from '@xpo-ltl/sdk-customer';
import { InvoiceModeCd, InvoiceTransmitStatusCd, InvoicePresentationFormatCd } from '@xpo-ltl/sdk-common';

import { NotificationService } from '@xpo-ltl/data-api';
import { ConditioningService } from '@xpo-ltl/common-services';
import { ConfigManagerService } from '@xpo-ltl/config-manager';

import * as _ from 'lodash';
import { BehaviorSubject, Observable, of } from 'rxjs';
import { catchError, map, take, tap } from 'rxjs/operators';

import { ResendInvoicesDialogData, ResendPreferences } from '@shared/models';
import { FileOptions, ConfigManagerProperties, AttachmentAsOptions, ResendValues, CriticalTransmissionDate } from '@shared/enums';

import { InvoiceAuditorService } from '@shared/services/invoice-auditor/invoice-auditor.service';

import { ResendInvoicesDialogComponent } from '@shared/dialogs/resend-invoices-dialog/resend-invoices-dialog.component';
import { ResendClosedInvoicesDialogComponent } from '@shared/dialogs/resend-closed-invoices-dialog/resend-closed-invoices-dialog.component';

export interface TransmissionMediumsResult {
  customer: AcctBasicDetails;
  preferences: Preference[];
}

@Injectable({
  providedIn: 'root',
})
export class ResendInvoiceService {

  private resendVisibility$ = new BehaviorSubject<boolean>(false);

  private forceTraditionalMode: boolean;
  private ediModes = [InvoiceModeCd.EDI];
  private paperModes = [InvoiceModeCd.PAPER];

  constructor(
    private dialog: MatDialog,
    private customerService: CustomerApiService,
    private invoiceApiService: InvoiceApiService,
    private notificationService: NotificationService,
    private conditioningService: ConditioningService,
    private configManagerService: ConfigManagerService,
    private invoiceAuditorService: InvoiceAuditorService
  ) {
    this.forceTraditionalMode = this.configManagerService.getSetting<boolean>(
      ConfigManagerProperties.forceTraditionalMode
    );
  }

  getTransmissionMediums(invoice: Invoice): Observable<DetermineInvoicePreferenceResp> {
    return this.determineInvoicePreference(invoice).pipe(
      take(1),
      catchError((error) => {
        return of(undefined);
      })
    );
  }

  lookupCustByMadCode(madCd: string): Observable<AcctBasicDetails> {
    const request = new GetAcctBasicDetailsRqst();
    request.acctInstId = madCd;
    return this.customerService.getAcctBasicDetails(request).pipe(
      take(1),
      map((response: GetAcctBasicDetailsResp) => {
        return response && response.acctBasicDetails ? response.acctBasicDetails : undefined;
      })
    );
  }

  handleResendInvoices(invoices: Invoice[] | InvoiceSummary[], extraData: ResendInvoicesDialogData): Observable<any> {
    return new Observable((observer) => {
      const complete = (result) => {
        observer.next(result);
        observer.complete();
      };

      const invoiceList = (invoices as Array<Invoice | InvoiceSummary>).map((invoice: any) => {
        const pro = !!invoice.invoiceClobData
          ? invoice.invoiceClobData.proNbr
          : this.conditioningService.conditionProNumber(invoice.proNbr, 11);
        const dmsInvoiceImageUrl =
          !!invoice.invoiceHeader && invoice.invoiceHeader.dmsInvoiceImageUrl
            ? invoice.invoiceHeader.dmsInvoiceImageUrl
            : invoice.dmsInvoiceImageUrl
              ? invoice.dmsInvoiceImageUrl
              : null;
        const invoiceStatus = invoice.invoiceHeader ?
          this.getTransmissionStatus(invoice.invoiceHeader.transmitStatusCd) : null;
        const isClosed = invoice.status === 'Closed' || invoiceStatus === 'Closed';
        const sendTo = !!invoice.invoiceClobData
          ? invoice.invoiceClobData.sendTo.custMadCd
          : invoice.sendTo;

        return { pro, isClosed, dmsInvoiceImageUrl, sendTo };
      });

      const hasClosedInvoicesSelected = invoices.some((row) => {
        const invoiceStatus = row.invoiceHeader ? this.getTransmissionStatus(row.invoiceHeader.transmitStatusCd) : null;
        return row.status === 'Closed' || invoiceStatus === 'Closed';
      });

      if (hasClosedInvoicesSelected) {
        let resendClosedInvoicesDialog: MatDialogRef<ResendClosedInvoicesDialogComponent>;
        const data = new ResendInvoicesDialogData(null, invoiceList, null);

        resendClosedInvoicesDialog = this.dialog.open(ResendClosedInvoicesDialogComponent, { data, width: '600px' });
        resendClosedInvoicesDialog
          .afterClosed()
          .pipe(take(1))
          .subscribe(result => {
            if (result) {
              this.showResendDialog(invoices, result.invoices, extraData, complete);
            } else {
              complete({ success: false });
            }
          });
      } else {
        this.showResendDialog(invoices, invoiceList, extraData, complete);
      }
    });
  }

  showTransmissionModes(modes: PreferredTransmissionMode[] | string[]): string {
    if (!modes.length) {
      return null;
    }

    const firstValue = modes[0];
    let newModes: string[];

    if (typeof firstValue === 'string') {
      newModes = modes as string[];
    } else {
      newModes = (modes as PreferredTransmissionMode[])
        .map((mode: PreferredTransmissionMode) => mode.invoiceModeCd)
        .map((mode: InvoiceModeCd) => `${mode}`);
    }
    const transmissionModes = this.mapEdiTransmissionModes(newModes);
    const dataArr = new Set(transmissionModes);
    return [...dataArr].sort().join(', ');
  }

  setResendVisibility(pro: number): Observable<boolean> {
    return this.isValidToBeSent$(`${pro}`)
      .pipe(
        take(1),
        tap((valid) => {
          this.resendVisibility$.next(valid);
        }),
        map(() => {
          return true;
        })
      );
  }

  getResendVisibility$(): Observable<boolean> {
    return this.resendVisibility$;
  }

  isValidToBeSent$(proParam: string): Observable<boolean> {
    const request = new ListInvoiceSummaryForResendRqst();
    const pro = this.conditioningService.conditionProNumber(proParam, 11);
    request.proNbrs = [pro];
    return this.invoiceApiService.listInvoiceSummaryForResend(request)
      .pipe(
        map((response: ListInvoiceSummaryForResendResp) => {
          const invoiceSummary = response.invoiceSummary || [];
          const validPros = invoiceSummary.map(invoice => invoice.proNbr);
          return validPros.includes(pro);
        })
      );
  }

  private mapEdiTransmissionModes(modes: string[]): string[] {
    const ediLegacyDisplayValue = 'EDI Legacy';

    return modes.map((mode: string) => {
      switch (mode) {
        case InvoiceModeCd.EDI:
          return ediLegacyDisplayValue;
        case InvoiceModeCd.EDI_TRANSFORMATION:
          return `${InvoiceModeCd.EDI}`;
        default:
          return mode;
      }
    });
  }

  private getTransmissionStatus(transmitStatusCd: string): string {
    const transmissionStatus = [`${InvoiceTransmitStatusCd.PAID}`, `${InvoiceTransmitStatusCd.PAID_BELOW_THRESHOLD}`];

    return transmissionStatus.includes(transmitStatusCd) ? 'Closed' : 'Open';
  }

  private showResendDialog(allInvoices, selectedInvoices, extraData: ResendInvoicesDialogData, complete) {
    let resendInvoicesDialog: MatDialogRef<ResendInvoicesDialogComponent>;

    const data = new ResendInvoicesDialogData(extraData.type, selectedInvoices, extraData.isMultiple);

    resendInvoicesDialog = this.dialog.open(ResendInvoicesDialogComponent, { data });
    resendInvoicesDialog
      .afterClosed()
      .pipe(take(1))
      .subscribe((result) => {
        resendInvoicesDialog = undefined;
        if (result) {
          const group = allInvoices.filter(invoice => {
            const pro = this.conditioningService.conditionProNumber(invoice.proNbr ||
              invoice.invoiceClobData.proNbr, 11);
            return selectedInvoices.some(selectedInvoice => pro === selectedInvoice.pro ||
              (selectedInvoice.invoiceClobData && selectedInvoice.invoiceClobData.proNbr === pro));
          });
          this.resendInvoices(group, result)
            .pipe(take(1))
            .subscribe((success) => {
              complete({ success, affectedRows: group });
            });
        } else {
          complete({ success: false });
        }
      });
  }

  private resendInvoices(
    invoices: Invoice[] | InvoiceSummary[],
    resendPreferences: ResendPreferences
  ): Observable<boolean> {
    return new Observable((observer) => {
      let obs$: Observable<any>;
      if (
        (this.paperModes.includes(resendPreferences.invoiceModeCd) && this.forceTraditionalMode) ||
        this.ediModes.includes(resendPreferences.invoiceModeCd)
      ) {
        const submitRequest = this.createTraditionalRequestForResending(invoices, resendPreferences);
        obs$ = this.invoiceApiService.submitResendInvoices(submitRequest).pipe(take(1));
      } else {
        const ensembleRequest = this.createEnsembleRequestForResending(invoices, resendPreferences);
        obs$ = this.invoiceApiService.startInvResendEnChEnsemble(ensembleRequest).pipe(take(1));
      }

      obs$.subscribe(
        (response) => {
          if (response) {
            const id = response.ensembleInstId ? response.ensembleInstId : response.requestId;
            this.notificationService.showSnackBarMessage(
              `Request submitted successfully. An email will be sent, please check your inbox for further details.`,
              {
                durationInMillis: this.configManagerService.getSetting<number>(
                  ConfigManagerProperties.successToastDuration
                ),
                status: 'success',
              }
            );
            observer.next(true);
          } else {
            this.notificationService.showSnackBarMessage(`Failed to re-send invoices for selected PROs.`, {
              durationInMillis: this.configManagerService.getSetting<number>(
                ConfigManagerProperties.errorToastDuration
              ),
              status: 'error',
            });
            observer.next(false);
          }
        },
        (error) => {
          this.notificationService.showSnackBarMessage(`Failed to re-send invoices for selected PROs.`, {
            durationInMillis: this.configManagerService.getSetting<number>(ConfigManagerProperties.errorToastDuration),
            status: 'error',
          });
          observer.next(false);
        }
      );
    });
  }

  private determineInvoicePreference(invoice: Invoice): Observable<DetermineInvoicePreferenceResp> {
    const pathParams2 = new DetermineInvoicePreferenceRqst();
    pathParams2.shipmentInstId = `${invoice.invoiceDetail.shipmentInfo.shipmentInstId}`;
    pathParams2.invoiceInfo = new InvoiceInfo();
    pathParams2.invoiceInfo.cisCustomerNbr = invoice.invoiceHeader.sentToPartyCisNbr;
    pathParams2.invoiceInfo.billTypeCd = invoice.invoiceHeader.billTypeCd;
    pathParams2.invoiceInfo.invoiceTypeCd = invoice.invoiceHeader.invoiceTypeCd;
    return this.invoiceApiService.determineInvoicePreference(pathParams2).pipe(take(1));
  }

  private createTraditionalRequestForResending(
    invoices: Invoice[] | InvoiceSummary[],
    resendPreferences: ResendPreferences
  ): SubmitResendInvoicesRqst {
    const request = new SubmitResendInvoicesRqst();

    request.invoiceModeCd = resendPreferences.invoiceModeCd;
    request.isPoundToPaper = false;
    request.isSlashToEdi = false;
    request.presentationFormatCd = resendPreferences.attachmentFormat;
    request.requesterEmailId = this.invoiceAuditorService.user.emailAddress;
    request.requesterEmployeeId = this.invoiceAuditorService.user.employeeId;
    request.shipment = [];
    if (resendPreferences.mailCompanyName && resendPreferences.invoiceModeCd === InvoiceModeCd.PAPER) {
      const nameAddr = new InvoiceShipmentParty();
      nameAddr.addressLine1 = resendPreferences.mailAddress;
      nameAddr.city = resendPreferences.mailCity;
      nameAddr.countryCd = resendPreferences.mailCountry;
      nameAddr.name1 = resendPreferences.mailCompanyName;
      nameAddr.postalCd = resendPreferences.mailZip;
      nameAddr.stateCd = resendPreferences.mailState;
      request.mailingAddress = nameAddr;
    }

    invoices.forEach((invoice) => {
      const shipment = new ShipmentBasic();

      if (invoice['invoiceDetail']) {
        // from View Invoice tab
        const item = invoice as Invoice;
        shipment.shipmentInstId = item.invoiceDetail.shipmentInfo.shipmentInstId;
        shipment.proNumber = item.invoiceDetail.shipmentInfo.proNbr;
        shipment.pickupDate = item.invoiceDetail.shipmentInfo.pickupDate;
        shipment.chargeToCd = item.invoiceHeader.chargeToCd;
      } else {
        // from Resend Invoices tab
        const item = invoice as InvoiceSummary;
        shipment.shipmentInstId = item.shipmentInstId;
        shipment.proNumber = item.proNbr;
        shipment.pickupDate = item['pickupDateRaw'];
        shipment.chargeToCd = item.chargeToCd;
      }
      request.shipment.push(shipment);
    });

    return request;
  }

  private createEnsembleRequestForResending(
    invoices: Invoice[] | InvoiceSummary[],
    resendPreferences: ResendPreferences
  ): StartInvResendEnChEnsembleRqst {
    const ensembleRequest = new StartInvResendEnChEnsembleRqst();
    const ensemblePayload = new StartInvoiceResendEnsemblePayload();

    if (resendPreferences.faxNumber) {
      ensemblePayload.sendTo = [resendPreferences.faxNumber];
    }
    if (resendPreferences.emailAddressList && resendPreferences.emailAddressList.length) {
      ensemblePayload.sendTo = resendPreferences.emailAddressList;
    }
    if (resendPreferences.emailSubject) {
      ensemblePayload.emailSubject = resendPreferences.emailSubject;
    }
    if (resendPreferences.mailCompanyName) {
      const nameAddr = new InvoiceShipmentParty();
      nameAddr.addressLine1 = resendPreferences.mailAddress;
      nameAddr.city = resendPreferences.mailCity;
      nameAddr.countryCd = resendPreferences.mailCountry;
      nameAddr.name1 = resendPreferences.mailCompanyName;
      nameAddr.postalCd = resendPreferences.mailZip;
      nameAddr.stateCd = resendPreferences.mailState;
      ensemblePayload.mailingAddress = nameAddr;
    }
    ensemblePayload.linkAttachedInd = resendPreferences.attachAs === AttachmentAsOptions.Link;
    ensemblePayload.documentTypeCds = resendPreferences.attachmentList;
    ensemblePayload.addCoverPageInd = !!resendPreferences.fileOptions
      ? resendPreferences.fileOptions[FileOptions.AddCoverPage]
      : false;
    ensemblePayload.oneInvoicePerEmailInd = !!resendPreferences.fileOptions
      ? resendPreferences.fileOptions[FileOptions.OneInvoicePerEmail]
      : false;
    ensemblePayload.oneProPerPdfInd = !!resendPreferences.fileOptions
      && resendPreferences.fileOptions[FileOptions.OneProPerPDF]
      ? resendPreferences.fileOptions[FileOptions.OneProPerPDF]
      : false;
    ensemblePayload.renameFileInd = !!resendPreferences.fileOptions
      && resendPreferences.fileOptions[FileOptions.RenameFilename]
      ? resendPreferences.fileOptions[FileOptions.RenameFilename]
      : false;
    ensemblePayload.invoiceModeCd = resendPreferences.invoiceModeCd;
    ensemblePayload.isPoundToPaper = false;
    ensemblePayload.isSlashToEdi = false;
    ensemblePayload.presentationFormatCd =
      resendPreferences.attachmentList && resendPreferences.attachmentList.length
        ? InvoicePresentationFormatCd.PDF_IMAGE
        : resendPreferences.attachmentFormat;
    ensemblePayload.requesterEmailId = this.invoiceAuditorService.user.emailAddress;
    ensemblePayload.requesterEmployeeId = this.invoiceAuditorService.user.employeeId;
    ensemblePayload.shipment = [];

    invoices.forEach((invoice) => {
      const shipment = new ShipmentBasic();

      if (invoice['invoiceDetail']) {
        const item = invoice as Invoice;
        shipment.shipmentInstId = item.invoiceDetail.shipmentInfo.shipmentInstId;
        shipment.proNumber = item.invoiceDetail.shipmentInfo.proNbr;
        shipment.pickupDate = item.invoiceDetail.shipmentInfo.pickupDate;
        shipment.chargeToCd = item.invoiceHeader.chargeToCd;
      } else {
        const item = invoice as InvoiceSummary;
        shipment.shipmentInstId = item.shipmentInstId;
        shipment.proNumber = item.proNbr;
        shipment.pickupDate = item['pickupDateRaw'];
        shipment.chargeToCd = item.chargeToCd;
      }
      ensemblePayload.shipment.push(shipment);
    });

    ensembleRequest.payload = ensemblePayload;
    ensembleRequest.ensembleName = 'resendInvoices';
    // request.businessKey1 = invoice[0].invoiceDetail.shipmentInfo.shipmentInstId;

    return ensembleRequest;
  }
}

import { Pipe, PipeTransform } from '@angular/core';
import { ChargeToCdPipe } from '../charge-to-cd/charge-to-cd.pipe';
import { InvoiceSummary, InvoiceDataHeader } from '@xpo-ltl/sdk-invoice';
import { BillTypeCd } from '@xpo-ltl/sdk-common';

@Pipe({
  name: 'debtorType',
})
export class DebtorTypePipe extends ChargeToCdPipe implements PipeTransform {
  transform(value: InvoiceDataHeader | InvoiceSummary, args?: any): string {
    let formattedValue;
    if (value) {
      if (value instanceof InvoiceSummary) {
        formattedValue = super.transform(value.chargeToCd, args);
      } else {
        formattedValue = super.transform(value.chargeToCd, args);

        if (value.billTypeCd === BillTypeCd.THIRD_PARTY) {
          formattedValue = `${formattedValue} (${BillTypeCd.THIRD_PARTY})`;
        }
      }
    }
    return formattedValue;
  }
}

import { Injectable } from '@angular/core';

import { BehaviorSubject, Observable } from 'rxjs';

import { XpoLtlEmailService, XpoLtlLoggedInUserService } from '@xpo-ltl/ngx-ltl';
import { ConfigManagerService } from '@xpo-ltl/config-manager';
import { FeedbackConfigInterface } from '@xpo-ltl/ngx-ltl-core';
import { ErrorMessageParser, NotificationService } from '@xpo-ltl/data-api';

import { ConfigManagerProperties } from '../../enums';

@Injectable({
  providedIn: 'root',
})
export class FeedbackService {
  private feedbackConfigBehaviorSubject = new BehaviorSubject<FeedbackConfigInterface>(undefined);

  constructor(
    private emailService: XpoLtlEmailService,
    private loginService: XpoLtlLoggedInUserService,
    private notificationService: NotificationService,
    private configManagerService: ConfigManagerService,
  ) {
    const generateFeedbackConfig = (user): FeedbackConfigInterface => {
      return {
        disableFromNameInput: true,
        disableSubjectInput: true,
        subject: this.configManagerService.getSetting(ConfigManagerProperties.feedbackSubject),
        fromEmail: user.emailAddress,
        fromName: `${user.givenName || user.displayName} ${user.lastName}`,
        onSendCallback: (message): Observable<boolean> => {
          return this.sendEmail(message);
        },
      };
    };

    this.loginService
      .getLoggedInUser(this.configManagerService.getSetting(ConfigManagerProperties.loggedInUserRoot))
      .subscribe((user) => {
        if (user) {
          this.feedbackConfigBehaviorSubject.next(generateFeedbackConfig(user));
        }
      });

    this.loginService.userLoggedIn$.subscribe(() => {
      this.loginService
        .getLoggedInUser(this.configManagerService.getSetting(ConfigManagerProperties.loggedInUserRoot))
        .subscribe((user) => {
          if (user) {
            this.feedbackConfigBehaviorSubject.next(generateFeedbackConfig(user));
          }
        });
    });
  }

  getFeedbackConfig$(): Observable<FeedbackConfigInterface> {
    return this.feedbackConfigBehaviorSubject.asObservable();
  }

  private sendEmail(message): Observable<boolean> {
    return new Observable(subscriber => {
      this.emailService
        .sendEmail(
          this.feedbackConfigBehaviorSubject.value.fromName,
          this.feedbackConfigBehaviorSubject.value.fromEmail,
          this.configManagerService.getSetting(ConfigManagerProperties.feedbackToAddress),
          this.configManagerService.getSetting(ConfigManagerProperties.feedbackSubject),
          `${message} - ${this.configManagerService.getSetting(ConfigManagerProperties.appName)} - ${this.feedbackConfigBehaviorSubject.value.fromName
          }`
        )
        .subscribe(
          () => {
            this.notificationService.showSnackBarMessage('Feedback sent', {
              durationInMillis: this.configManagerService.getSetting(ConfigManagerProperties.successToastDuration),
              status: 'success',
            });

            subscriber.next(true);
            subscriber.complete();
          },
          (error): void => {
            this.notificationService.showSnackBarMessage(`${ErrorMessageParser.parseMessage(error) || error}.`, {
              durationInMillis: this.configManagerService.getSetting(ConfigManagerProperties.errorToastDuration),
              status: 'error',
            });

            subscriber.error(false);
            subscriber.complete();
          }
        );
    });

  }
}

export enum ConfigManagerProperties {
  isProd = 'isProd',
  production = 'production',
  scopeOptions = 'scopeOptions',
  apiUrl = 'apiUrl',
  secretToken = 'secretToken',
  region = 'region',
  appName = 'appName',
  buildVersion = 'buildVersion',
  loggedInUserRoot = 'loggedInUserRoot',
  customerApiEndpoint = 'customerApiEndpoint',
  invoiceApiEndpoint = 'invoiceApiEndpoint',
  userApiEndpoint = 'userApiEndpoint',
  dmsApiEndpoint = 'dmsApiEndpoint',
  userContextPath = 'userContextPath',
  errorToastDuration = 'errorToastDuration',
  warningToastDuration = 'warningToastDuration',
  successToastDuration = 'successToastDuration',
  feedbackToAddress = 'feedbackToAddress',
  feedbackSubject = 'feedbackSubject',
  imageCorpCode = 'imageCorpCode',
  imageDocClass = 'imageDocClass',
  maxReleaseRows = 'maxReleaseRows',
  maxResendRows = 'maxResendRows',
  maxResendAttachmentSize = 'maxResendAttachmentSize',
  maxResendInvoicesPerEmail = 'maxResendInvoicesPerEmail',
  arinAverage = 'arinAverage',
  blAverage = 'blAverage',
  drAverage = 'drAverage',
  loaAverage = 'loaAverage',
  wiAverage = 'wiAverage',
  ncicAverage = 'ncicAverage',
  custAverage = 'custAverage',
  cutoffDate = 'cutoffDate',
  supportAddress = 'supportAddress',
  forceTraditionalMode = 'forceTraditionalMode',
}

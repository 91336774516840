import { Pipe, PipeTransform } from '@angular/core';
import { EmptyCurrencyPipe } from '../empty-currency/empty-currency.pipe';
import { InvoiceLine } from '@xpo-ltl/sdk-invoice';

@Pipe({
  name: 'rate',
})
export class RatePipe extends EmptyCurrencyPipe implements PipeTransform {
  transform(value: InvoiceLine, args?: any): any {
    if (value && (value.minimumChargeInd || value.rateAmt === -0.01 || value.rateAmt === -0.0001)) {
      return 'M';
    }
    return super.transform(value ? value.rateAmt : 0, args);
  }
}

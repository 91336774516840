import { AbstractControl, ValidatorFn } from '@angular/forms';

export const commaSeparatedEmail = (): ValidatorFn => {
  return (control: AbstractControl): { [key: string]: any } => {
    if (control && control.value) {

      const emailPattern = /^([\w+-.%]+@[\w-.]+\.[A-Za-z]{2,4},?)+$/;

      const formatError = {
        emailAddressFormat: {
          value: control.value
        }
      };

      const maxLengthError = {
        emailAddressMaxLength: {
          value: control.value
        }
      };

      const maxAddressesEnteredError = {
        emailAddressInvalid: {
          value: control.value
        }
      };

      let emails = control.value.replace(/\s/g, '');

      emails = emails.split(',').filter((e: string) => e && e.trim() !== '');

      const lengthExceedingEmails = emails.some(email => email.length > 120);
      if (lengthExceedingEmails) {
        return maxLengthError;
      }
      const isNotEmailAddress = emails.some(email => !emailPattern.test(email));
      return isNotEmailAddress ? formatError : null;
    }
    return null;
  };
};
